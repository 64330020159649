import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormArray, FormBuilder, Validators } from '@angular/forms';

import { Subject, Subscription } from 'rxjs';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { DesignService } from '../../../../services/design.service';

@Component({
  selector: 'app-zendesk-connector-modal',
  templateUrl: './zendesk-connector-modal.component.html',
  styleUrls: ['./zendesk-connector-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ZendeskConnectorModalComponent implements OnInit, OnDestroy {
  @Input() private journey: any;
  @Input() private connector: any;
  @Output() private onSaveConnector = new EventEmitter<any>();
  
  connectorSubscription: Subscription;
  moveStep: Subject<any> = new Subject<any>();

  zendeskPhases: any = {
    steps: [
      { name: 'General settings', submitted: false },
      { name: 'Ticket details', submitted: false },
    ],
    current: 0
  };

  zendeskForm = this.fb.group({
    steps: this.fb.array([
      this.fb.group({
        url: ['', Validators.required],
        email: ['', Validators.required],
        apiToken: ['', Validators.required]
      }),
      this.fb.group({
        subject: ['', Validators.required],
        body: ['', Validators.required]
      })
    ])
  });

  get stepsForm() { return this.zendeskForm.get('steps') as FormArray }

  get stepOne() { return this.stepsForm.at(0) }
  get stepTwo() { return this.stepsForm.at(1) }

  constructor(public activeModal: NgbActiveModal, private designService: DesignService, private fb: FormBuilder) { }

  ngOnInit() {
    if (this.connector.id) {
      this.stepsForm.patchValue([
        {
          url: this.connector.url,
          email: this.connector.email,
          apiToken: this.connector.apiToken
        },
        {
          subject: this.connector.subject,
          body: this.connector.body
        }
      ]);
    }
  }

  ngOnDestroy() {
    if (this.connectorSubscription instanceof Subscription) this.connectorSubscription.unsubscribe();
  }

  nextStep() {
    this.moveStep.next('next');
  }

  saveConnector($event) {
    const data = { ...this.connector, ...$event, journeyId: this.journey.id };

    if (this.connector.id) {
      this.connectorSubscription = this.designService.editConnector('zendesk', data).subscribe(() => {
        this.activeModal.close();
        this.onSaveConnector.emit();
      });
    } else {
      this.connectorSubscription = this.designService.addConnector('zendesk', data).subscribe(() => {
        this.activeModal.close();
        this.onSaveConnector.emit();
      });
    }
  }

}
