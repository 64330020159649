import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'spinner-loader',
  templateUrl: './spinner-loader.component.html',
  styleUrls: ['./spinner-loader.component.scss']
})
export class SpinnerLoaderComponent implements OnInit {
  @Input() size: 'xs' | 'sm' | 'lg' | '2x' | '3x' | '4x' | '5x' | '6x' | '7x' | '8x' | '9x' | '10x';

  constructor() { }

  ngOnInit() {
  }

}
