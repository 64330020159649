import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'market-templates',
  templateUrl: './market-templates.component.html',
  styleUrls: ['./market-templates.component.scss']
})
export class MarketTemplatesComponent implements OnInit {
  @Input() templates: Array<any>;
  @Output() onBuyProduct = new EventEmitter<any>();
  
  constructor() { }

  ngOnInit() {
  }

  buyItem() {
    this.onBuyProduct.emit();
  }

}
