import { Component, OnInit, ViewChild, Input, OnDestroy } from '@angular/core';
import { NgForm } from '@angular/forms';

import { of, Observable, Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, catchError, map } from 'rxjs/operators';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { NotificationService } from '../../../services/notification.service';
import { AdminService } from '../../../services/admin.service';
import { IntentService } from '../../../services/intent.service';

@Component({
  selector: 'topics-modal',
  templateUrl: './topics-modal.component.html',
  styleUrls: ['./topics-modal.component.scss']
})
export class TopicsModalComponent implements OnInit, OnDestroy {
  @ViewChild('topicForm', { static: true }) private topicForm: NgForm;
  @Input() trainer: any;

  tagSuggestions = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      switchMap(term =>
        this.intentService.getTagsSuggestions(term, {type: 'TOPIC'}).pipe(
          map(suggestions => suggestions.filter(tag => this.trainer.tags.indexOf(tag.name) === -1)),
          catchError(() => {
            return of([]);
          })
        )
      )
    )

  tagSuggestionFormat = (value: any) => value.name;
  tagSuggestionSelect = (value: any) => value.name;
  
  updateTagSubscription: Subscription;
  onUpdateTags: Subject<any> = new Subject<any>();

  constructor(private adminService: AdminService, public activeModal: NgbActiveModal, private notificationService: NotificationService, private intentService: IntentService) { }

  ngOnInit() {
  }

  ngOnDestroy() {
    if (this.updateTagSubscription instanceof Subscription) this.updateTagSubscription.unsubscribe();
  }

  addTag($event) {
    this.trainer.tags.push($event.item.name);

    this.topicForm.resetForm();
  }

  removeTag(topicIndex: number) {
    this.trainer.tags.splice(topicIndex, 1);
  }

  updateTagsTopic() {
    const data = {
      tags: this.trainer.tags,
      userId: this.trainer.id
    };
    this.updateTagSubscription = this.adminService.updateTrainerTags(data).subscribe((response: any) => {
      this.activeModal.close();
      this.notificationService.showToast('Topics correctly assigned', { type: 'success' });
      this.onUpdateTags.next();
    });
  }

}
