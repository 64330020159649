import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DialogService {

  constructor(private http: HttpClient) { }

  getConversations(params: any, pageNumber: number, pageSize: number): Observable<any> {
    const httpOptions = {
      params: new HttpParams()
        //.set('environment', params.environment)
        .set('pageSize', pageSize.toString())
        .set('pageIndex', pageNumber.toString())
    };
    
    if (params.sessionId) httpOptions.params = httpOptions.params.append('sessionId', params.sessionId);
    if (params.startDate) httpOptions.params = httpOptions.params.append('startDate', params.startDate);
    if (params.endDate) httpOptions.params = httpOptions.params.append('endDate', params.endDate);
    if (params.approved != null) httpOptions.params = httpOptions.params.append('approved', params.approved);
    if (params.archived) httpOptions.params = httpOptions.params.append('archived', params.archived);
    if (params.fallback) httpOptions.params = httpOptions.params.append('fallback', params.fallback);
    if (params.minSentimentMedia) httpOptions.params = httpOptions.params.append('minSentimentMedia', params.minSentimentMedia);
    if (params.maxSentimentMedia) httpOptions.params = httpOptions.params.append('maxSentimentMedia', params.maxSentimentMedia);
    if (params.agentChannel) httpOptions.params = httpOptions.params.append('agentChannel', params.agentChannel);
    if (params.userId) httpOptions.params = httpOptions.params.append('userId', params.userId);
    if (params.userIdNotNull) httpOptions.params = httpOptions.params.append('userIdNotNull', params.userIdNotNull);
    if (params.withoutTopics) httpOptions.params = httpOptions.params.append('withoutTopics', params.withoutTopics);
    if (params.tags) httpOptions.params = httpOptions.params.append('tags', params.tags);
    if (params.sortBy) httpOptions.params = httpOptions.params.append('sortBy', params.sortBy);

    return this.http.get(`${environment.API.orchestrator_analysis}/conversations/view`, httpOptions);
  }

  archiveConversation(data: any): Observable<any> {
    return this.http.put(`${environment.API.orchestrator_analysis}/conversations/archived`, data);
  }

  unarchiveConversation(data: any): Observable<any> {
    return this.http.put(`${environment.API.orchestrator_analysis}/conversations/unarchived`, data);
  }

  assignConversation(data: any, idConversation: number, idUser: number): Observable<any> {
    const httpOptions = {
      params: new HttpParams()
        .set('idConversation', idConversation.toString())
        .set('idUser', idUser.toString())
    };
    return this.http.post(`${environment.API.orchestrator_analysis}/assigned/conversations`, data, httpOptions);
  }

  unassignConversation(idConversation: number): Observable<any> {
    return this.http.delete(`${environment.API.orchestrator_analysis}/assigned/conversations/deleteByConversationId/${idConversation}`);
  }

  getInteractions(sessionId: string): Observable<any> {
    const httpOptions = {
      params: new HttpParams().set('sessionId', sessionId)
    };

    return this.http.get(`${environment.API.orchestrator_analysis}/messages/view`, httpOptions);
  }

  getInteractionHistory(params: any, pageNumber: number, pageSize: number): Observable<any> {
    const httpOptions = {
      params: new HttpParams()
        .set('pageSize', pageSize.toString())
        .set('pageIndex', pageNumber.toString())
    };

    if (params.startDate) httpOptions.params = httpOptions.params.append('startDate', params.startDate);
    if (params.endDate) httpOptions.params = httpOptions.params.append('endDate', params.endDate);
    if (params.siebelId) httpOptions.params = httpOptions.params.append('siebelId', params.siebelId);
    if (params.dfIntentName) httpOptions.params = httpOptions.params.append('dfIntentName', params.dfIntentName);
    if (params.userQuery) httpOptions.params = httpOptions.params.append('userQuery', params.userQuery);
    if (params.rating) httpOptions.params = httpOptions.params.append('operatorRatings', params.rating);
    if (params.agentChannel) httpOptions.params = httpOptions.params.append('agentChannel', params.agentChannel);
    if (params.sortBy) httpOptions.params = httpOptions.params.append('sortBy', params.sortBy);

    return this.http.get(`${environment.API.orchestrator_analysis}/messages/view/history`, httpOptions);
  }

  getEntities(params: any): Observable<any> {
    const httpOptions = {
      params: new HttpParams().set('intentName', params.intentName)
    };
    return this.http.get(`${environment.API.orchestrator_analysis}/nlu/integrations/intents/parameters`, httpOptions);
  }

  getIntents(params?: any): Observable<any> {
    const httpOptions = {
      params: new HttpParams()
        .set('onlyName', 'false')
    };

    if (params && params.journeyApiKey) httpOptions.params = httpOptions.params.append('journeyApiKey', params.journeyApiKey);

    return this.http.get(`${environment.API.orchestrator_analysis}/nlu/integrations/intents/search`, httpOptions);
  }

  getIntent(name: string): Observable<any> {
    const httpOptions = {
      params: new HttpParams()
        .set('intentName', name)
        .set('onlyName', 'false')
    };
    return this.http.get(`${environment.API.orchestrator_analysis}/nlu/integrations/intents`, httpOptions);
  }

  approveConversation(conversation: any): Observable<any> {
    return this.http.post(`${environment.API.orchestrator_analysis}/nlu/integrations/intents/training/phrases`, conversation);
  }

  getCsvExport(params: any): Observable<any> {
    const httpOptions = {
      responseType: 'text' as 'text',
      params: new HttpParams()
        .set('startDate', params.startDate.toString())
        .set('endDate', params.endDate.toString())
    };
    return this.http.get(`${environment.API.orchestrator_analysis}/operations/download/history`, httpOptions);
  }

}
