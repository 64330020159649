import { Component, OnInit, OnDestroy } from '@angular/core';

import { AuthenticationService } from '../../../../services/authentication.service';

@Component({
  selector: 'setting-profile',
  templateUrl: './setting-profile.component.html',
  styleUrls: ['./setting-profile.component.scss']
})
export class SettingProfileComponent implements OnInit, OnDestroy {

  get user() { return this.authenticationService.user }

  constructor(private authenticationService: AuthenticationService) { }

  ngOnInit() {
  }

  ngOnDestroy() {
  }

}
