import { Directive, Input, ElementRef, Renderer2, Output, EventEmitter, HostListener } from '@angular/core';

import { Subject } from 'rxjs';

@Directive({
  selector: '[entitySelection]',
  inputs: ['updateEntities']
})
export class EntitySelectionDirective {
  @Input('entitySelection') interaction: any;
  @Input() updateEntities: Subject<any>;
  @Output() onSelectEntity = new EventEmitter;

  @HostListener('mouseup', ['$event'])
  onMouseup($event) {
    //console.log('[MOUSEUP]', $event);
    if ( $event.view.getSelection().toString() && !($event.view.getSelection().focusNode.nodeValue.includes($event.view.getSelection().toString())) ) {
      $event.view.getSelection().empty();
      return;
    }

    if ($event.view.getSelection().toString()) {
      const relativeOffset = $event.view.getSelection().getRangeAt(0).getBoundingClientRect().left - this.el.nativeElement.getBoundingClientRect().left;
  
      this.onSelectEntity.emit({
        value: $event.view.getSelection().toString(),
        offset: relativeOffset + ($event.view.getSelection().getRangeAt(0).getBoundingClientRect().width / 2)
      });
    }

  }

  @HostListener('keyup', ['$event'])
  onKeyup($event) {
    //console.log('[KEYUP]', $event);
    let entitiesEdited: Array<string> = [];
    $event.target.querySelectorAll('span.entity').forEach(element => {
      this.interaction.dfEntitiesJson[element.dataset.key] = element.textContent;
      entitiesEdited.push(element.dataset.key);
    });
    this.checkEditableEntities(entitiesEdited);
  }

  constructor(private el: ElementRef, private render: Renderer2) { }

  ngOnInit() {
    this.updateEntities.asObservable().subscribe($event => {
      //console.log('[UPDATE_ENTITIES]', $event);
      this.markEntities($event);
    });
  }

  ngAfterViewInit() {
    this.markEntities(this.interaction.dfEntitiesJson);
  }

  markEntities(entities: any) {
    //console.log('[ENTITIES_MATCHES]', entities);
    this.render.setProperty(this.el.nativeElement, 'innerHTML', this.interaction.userQuery);
    
    if (!entities || Object.keys(entities).length === 0) return;

    let colorIndex = 0;
    for (const key in entities) {
      if (entities.hasOwnProperty(key) && entities[key].length > 0) {
        const cleanPhrase = entities[key].replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
        const replaceReg = new RegExp(cleanPhrase, 'gi');
        const replaceCode = this.el.nativeElement.innerHTML;

        this.render.setProperty(
          this.el.nativeElement,
          'innerHTML',
          replaceCode.replace(replaceReg, `<span class="entity matches_${colorIndex}" data-key="${key}">${entities[key]}</span>`)
        );

        colorIndex++;
      }
    }

    const entitiesElement = this.el.nativeElement.querySelectorAll('span.entity');

    entitiesElement.forEach(element => {      
      this.render.listen(element, 'click', ($event: any) => {
        this.onSelectEntity.emit({ value: $event.target.textContent, oldKey: $event.target.dataset.key, offset: $event.target.offsetLeft + ($event.target.offsetWidth / 2) });
      });
    });
  }

  checkEditableEntities(entities: Array<string>) {
    for (const key in this.interaction.dfEntitiesJson) {
      if (this.interaction.dfEntitiesJson.hasOwnProperty(key)) {
        if (entities.indexOf(key) === -1) delete this.interaction.dfEntitiesJson[key];
      }
    }
  }

}
