import { Component, OnInit, ViewChildren, QueryList, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { NgbModal, NgbDate } from '@ng-bootstrap/ng-bootstrap';

import { Pagination } from '../../classes/pagination';
import { PaginationSlide } from '../../classes/pagination-slide';
import { TableSortDirective } from '../../directives/table-sort.directive';
import { DialogService } from '../../services/dialog.service';
import { TrainingModalComponent } from '../modals/training-modal/training-modal.component';
import { AdminService } from '../../services/admin.service';
import { DesignService } from '../../services/design.service';

@Component({
  selector: 'app-training',
  templateUrl: './training.component.html',
  styleUrls: ['./training.component.scss']
})
export class TrainingComponent implements OnInit, OnDestroy {
  @ViewChildren(TableSortDirective) thead: QueryList<TableSortDirective>;

  subscriptions: Array<Subscription> = [];
  resultResolved: boolean;
  resultsPagination: Pagination = new Pagination();
  resultSlide: PaginationSlide;

  results: Array<any> = [];
  tagFilters: any = {};

  filter: any = {};
  filterFor: string = 'sessionId';
  idResearchTab: 'conversations' | 'assigned' | 'archived' = 'conversations';
  sentimentMedia = '';
  sidebarTags: { active: boolean, typeSelected?: string, label: string } = { active: false, label: 'All conversations' };

  constructor(private dialogService: DialogService, private modalService: NgbModal, private adminService: AdminService, private designService: DesignService) { }

  ngOnInit() {
    this.subscriptions['JourneySubscription'] = this.designService.getSessionJourney().subscribe(() => {
      this.searchItems(1);
    });
    this.getTagFilters();
  }

  ngOnDestroy() {
    Object.keys(this.subscriptions).forEach((key: string) => {
      this.subscriptions[key].unsubscribe();
    });
  }

  onSort({column, direction}: any) {
    this.thead.forEach((th: any) => {
      if (th.sortable !== column) {
        th.direction = '';
      }
    });

    this.filter.sortBy = (direction) ? `${column}:${direction}` : null;
    this.searchItems(this.resultsPagination.currentPage);
  }

  viewConversation(item: any) {
    this.resultSlide = new PaginationSlide(this.results, this.resultsPagination, item, 'sessionId');
    this.resultSlide.onMovePage.subscribe(async $event => {
      await this.searchItems($event.targetPage);
      this.resultSlide.changePage($event.direction, this.results);
    });

    const modalRef = this.modalService.open(TrainingModalComponent, {
      size: 'xl'
    });
    modalRef.componentInstance.conversation = item;
    modalRef.componentInstance.conversationSlide = this.resultSlide;
    modalRef.componentInstance.onUpdateResults.subscribe($event => this.searchItems(this.resultsPagination.currentPage));
  }

  async searchItems(pageSelected: number) {
    this.resultResolved = false;
    this.resultsPagination.onSelectPage(pageSelected);

    if (this.filterFor === 'sessionId') await this.searchConversations();
    if (this.filterFor === 'intent') await this.searchIntents();
    if (this.filterFor === 'phrase') await this.searchPhrase();

    this.resultResolved = true;
  }

  changeSentimentFilter() {
    switch (this.sentimentMedia) {
      case '':
        this.filter.minSentimentMedia = -1;
        this.filter.maxSentimentMedia = 1;
        break;
      case 'Positive':
        this.filter.minSentimentMedia = 0.25;
        this.filter.maxSentimentMedia = 1;
        break;
      case 'Neutral':
        this.filter.minSentimentMedia = -0.250001;
        this.filter.maxSentimentMedia = 0.249999;
        break;
      case 'Negative':
        this.filter.minSentimentMedia = -1;
        this.filter.maxSentimentMedia = -0.25;
        break;
    }
    this.searchItems(1);
  }

  switchIdResearchTab() {
    this.filter = {...this.filter,
      archived: null,
      userIdNotNull: null
    };
    if (this.idResearchTab === 'archived') this.filter.archived = 'true';
    else if (this.idResearchTab === 'assigned') this.filter.userIdNotNull = 'true';
    this.searchItems(1);
  }

  searchConversations(): Promise<void> {
    //console.log('[FILTERS]', this.filter);
    const params = {
      sessionId: this.filter.id || null,
      startDate: this.filter.fromDate ? new Date(this.filter.fromDate.year, this.filter.fromDate.month-1, this.filter.fromDate.day, 0, 0, 0).getTime() : null,
      endDate: this.filter.toDate ? new Date(this.filter.toDate.year, this.filter.toDate.month-1, this.filter.toDate.day, 23, 59, 59).getTime() : null,
      approved: this.filter.approved,
      archived: this.filter.archived || 'false',
      fallback: this.filter.fallback,
      minSentimentMedia: this.filter.minSentimentMedia || -1,
      maxSentimentMedia: this.filter.maxSentimentMedia || 1,
      agentChannel: this.filter.agentChannel || null,
      //userId: this.filter.userId || null,
      userIdNotNull: this.filter.userIdNotNull || 'false',
      withoutTopics: this.filter.withoutTopics || null,
      tags: this.filter.tags,
      sortBy: this.filter.sortBy || null
    };

    return this.dialogService.getConversations(params, this.resultsPagination.getPageIndex(), this.resultsPagination.pageSize).toPromise().then((response: any) => {
      //console.log('[CONVERSATIONS]', response);
      this.results = response.data;
      this.resultsPagination.updateTotals(response.totalElements);
    });
  }

  searchIntents(): Promise<void> {
    //console.log('[FILTERS]', this.filter);
    const params = {
      dfIntentName: this.filter.intent || null,
      startDate: this.filter.fromDate ? new Date(this.filter.fromDate.year, this.filter.fromDate.month - 1, this.filter.fromDate.day, 0, 0, 0).getTime() : null,
      endDate: this.filter.toDate ? new Date(this.filter.toDate.year, this.filter.toDate.month - 1, this.filter.toDate.day, 23, 59, 59).getTime() : null,
      agentChannel: this.filter.agentChannel || null,
      sortBy: this.filter.sortBy || null
    };

    return this.dialogService.getInteractionHistory(params, this.resultsPagination.getPageIndex(), this.resultsPagination.pageSize).toPromise().then((response: any) => {
      //console.log('[INTENTS]', response);
      this.results = response.data;
      this.resultsPagination.updateTotals(response.totalElements);
    });
  }

  searchPhrase(): Promise<void> {
    //console.log('[FILTERS]', this.filter);
    const params = {
      userQuery: this.filter.phrase || null,
      startDate: this.filter.fromDate ? new Date(this.filter.fromDate.year, this.filter.fromDate.month - 1, this.filter.fromDate.day, 0, 0, 0).getTime() : null,
      endDate: this.filter.toDate ? new Date(this.filter.toDate.year, this.filter.toDate.month - 1, this.filter.toDate.day, 23, 59, 59).getTime() : null,
      agentChannel: this.filter.agentChannel || null,
      sortBy: this.filter.sortBy || null
    };

    return this.dialogService.getInteractionHistory(params, this.resultsPagination.getPageIndex(), this.resultsPagination.pageSize).toPromise().then((response: any) => {
      //console.log('[PHRASE]', response);
      this.results = response.data;
      this.resultsPagination.updateTotals(response.totalElements);
    })
  }

  updateDate($event) {
    if ($event.type === 'from') {
      this.filter.fromDate = $event.date;
      if (this.filter.fromDate instanceof NgbDate) this.searchItems(1);
    }
    if ($event.type === 'to') {
      this.filter.toDate = $event.date;
      if (this.filter.toDate instanceof NgbDate) this.searchItems(1);
    }
  }

  archiveConversation(conversation: any) {
    const data = { id: conversation.id };
    this.subscriptions['ArchiveConversation'] = this.dialogService.archiveConversation(data).subscribe(async (response: any) => {
      if (!!(conversation.userEntity || {}).id) {
        await this.dialogService.unassignConversation(conversation.id).toPromise();
      }
      this.searchItems(this.resultsPagination.currentPage);
    });
  }

  getTagFilters() {
    this.subscriptions['TagFilters'] = this.adminService.getTrainerTagsTree().subscribe((response: any) => {
      this.tagFilters = response;
    });
  }

  selectTagFilter(tagName: Array<string> | string, tagType?: 'mine' | 'other' | 'allMine' | 'allOther' | 'without') {
    this.filter.withoutTopics = (tagType === 'without') ? true : null;
    if (Array.isArray(tagName)) {
      this.filter.tags = [];
      tagName.forEach(tag => this.filter.tags.push(tag));
      this.sidebarTags.label = (tagType === 'allMine') ? 'Your topics' : 'Other topics'
    } else {
      this.filter.tags = tagName;
      this.sidebarTags.label = (tagName) ? tagName : (tagType === 'without') ? 'Without topics' : 'All conversations';
    }

    this.sidebarTags = {...this.sidebarTags,
      active: false,
      typeSelected: tagType || null
    };
    this.searchItems(1);
  }

  resetFilters() {
    this.filter = {};
    this.sidebarTags = { active: false, label: 'All conversations' };
    this.searchItems(1);
  }
}
