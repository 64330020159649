import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';

import { Observable, of, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, catchError, map } from 'rxjs/operators';

import { MonitoringService } from '../../../../services/monitoring.service';
import { NotificationService } from '../../../../services/notification.service';

@Component({
  selector: 'setting-alerts',
  templateUrl: './setting-alerts.component.html',
  styleUrls: ['./setting-alerts.component.scss']
})
export class SettingAlertsComponent implements OnInit, OnDestroy {
  @ViewChild('alertForm', { static: true }) alertForm: NgForm;

  subscriptions: Array<Subscription> = [];

  userEmail: string;
  notificationAlert: any = {
    enabled: false,
    users: []
  };

  userSuggestions = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      switchMap(term =>
        this.monitoringService.getBillingUsers().pipe(
          map(users => users.filter(user => user.email.toLowerCase().indexOf(term.toLocaleLowerCase()) > -1)),
          catchError(() => {
            return of([]);
          })
        )
      )
    )
  
  userSuggestionFormat = (value: any) => value.email;
  //userSuggestionSelect = (value: any) => value.email;
  userSuggestionSelect = () => '';

  constructor(private monitoringService: MonitoringService, private notificationService: NotificationService) { }

  ngOnInit() {
    this.getNotifiedUser();
  }

  ngOnDestroy() {
    Object.keys(this.subscriptions).forEach((key: string) => {
      this.subscriptions[key].unsubscribe();
    });
  }

  chooseUser($event) {
    if (this.notificationAlert.users.some(user => user.id === $event.item.id)) return;
    this.notificationAlert.users.push($event.item);
  }

  removeUser(userId) {
    this.notificationAlert.users = this.notificationAlert.users.filter(user => user.id != userId);
    this.userEmail = '';
  }

  getNotifiedUser() {
    this.subscriptions['NotifiedUser'] = this.monitoringService.getNotifiedUser().subscribe((response: any) => {
      this.notificationAlert = {
        users: response,
        enabled: (response.length > 0)
      };
    });
  }

  setEmailAlert() {
    if (!this.notificationAlert.users.length && this.notificationAlert.enabled) return;

    const params = {
      enabled: this.notificationAlert.enabled
    };
    const data = this.notificationAlert.users.reduce((accumulator, currentValue) => {
      accumulator.push(currentValue.id);
      return accumulator;
    }, []);

    console.log('[PARAMS]', params);
    console.log('[DATA]', data);
    //return;

    this.subscriptions['AlertEmail'] = this.monitoringService.updateNotifiedUser(data, params).subscribe(() => {
      this.notificationService.showToast('Alert correctly updated', { type: 'success' });
      this.alertForm.resetForm();
      this.getNotifiedUser();
    });
  }

}
