import { Component, OnInit, OnDestroy, QueryList, ViewChildren, AfterViewInit, ElementRef } from '@angular/core';

import { Subscription } from 'rxjs';

import { MonitoringService } from '../../../services/monitoring.service';
import { DesignService } from '../../../services/design.service';
import { ResizedEvent } from 'angular-resize-event';
import { AnalyticsService } from '../../../services/analytics.service';

declare let google: any;
@Component({
  selector: 'requests-card',
  templateUrl: './requests-card.component.html',
  styleUrls: ['./requests-card.component.scss']
})
export class RequestsCardComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChildren('requestsChart') private requestsChart: QueryList<ElementRef>;

  subscriptions: Array<Subscription> = [];

  filter: any = {
    connector: 'dialogflow',
    days: 7,
    startDate: 0,
    endDate: 0
  };
  totalRequest: number;
  billingRequests: Array<any> = [];

  constructor(private monitoringservice: MonitoringService, private designService: DesignService, private analyticsService: AnalyticsService) { }

  ngOnInit() {
    google.charts.load('current', { 'packages': ['corechart'] });
    
    this.subscriptions['JourneySubscription'] = this.designService.getSessionJourney().subscribe(() => {
      this.getRequests();
    });
  }

  ngOnDestroy() {
    Object.keys(this.subscriptions).forEach((key: string) => {
      this.subscriptions[key].unsubscribe();
    });
  }

  ngAfterViewInit() {
    this.requestsChart.changes.subscribe(() => {
      //console.log('[REQUEST_CHART_ELEMENTS_CHANGES]');
      this.drawSessionsChart();
    });
  }

  onResized(event: ResizedEvent) {
    this.drawSessionsChart();
  }

  getTotalRequest(response): any {
    this.totalRequest = response.reduce((accumulator, currentValue) => {
      return accumulator + currentValue.value;
    }, 0);
  }

  getRequests() {
    this.subscriptions['RequestSubscription'] = this.monitoringservice.getRequestStats(this.filter.connector, this.filter.days, this.filter.startDate, this.filter.endDate).subscribe((response: any) => {
      if (!response.billingList.length) return;

      this.getTotalRequest(response.billingList);
      this.billingRequests = response.billingList;
      if (this.requestsChart.length) this.drawSessionsChart();
    })
  }

  drawSessionsChart() {
    google.charts.setOnLoadCallback(() => {
      let chartData = this.processChartData(this.billingRequests);
      let data = google.visualization.arrayToDataTable(chartData);

      let options = {
        curveType: 'none',
        legend: { position: 'none' },
        hAxis: {
          format: 'dd MMM y',
        },
        vAxis: {
          viewWindow: this.analyticsService.getAxisScale(chartData)
        },
        chartArea: { width: '95%' }
      };

      let chart = new google.visualization.LineChart(document.getElementById('requests_chart'));

      chart.draw(data, options);
    });
  }

  processChartData(data: any): Array<Array<any>> {
    let chartData: Array<Array<any>> = [];
    if (!data) return chartData;

    chartData.push(['Date', 'Sessions']);
    data.forEach((date: any) => {
      chartData.push([new Date(date.startDate), date.value]);
    });

    return chartData;
  }
}
