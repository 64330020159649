import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'output-context-field',
  templateUrl: './output-context-field.component.html',
  styleUrls: ['./output-context-field.component.scss']
})
export class OutputContextFieldComponent implements OnInit {
  @Input() outputContexts: FormArray;
  @Input() formSubmitted: boolean;

  constructor(private fb: FormBuilder) { }

  ngOnInit() {
  }

  addContext($event) {
    $event.preventDefault();
    this.outputContexts.push(
      this.fb.group({
        name: [$event.target.value, Validators.required],
        lifespan: [1, Validators.required]
      })
    );
    $event.target.value = '';
  }

}
