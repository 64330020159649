import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'button-item',
  templateUrl: './button-item.component.html',
  styleUrls: ['./button-item.component.scss']
})
export class ButtonItemComponent implements OnInit, AfterViewInit {
  @Input() itemGroup: FormGroup;
  @Input() formSubmitted: boolean;
  @Input() optional: boolean = false;

  constructor() { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.setValidation(this.itemGroup.get('type').value);

    this.itemGroup.get('type').valueChanges.subscribe((value) => {
      this.setValidation(value);
    });
  }

  setValidation(buttonType: string) {
    switch (buttonType) {
      case 'link':
        this.itemGroup.get('value').disable();
        this.itemGroup.get('url').enable();
        this.itemGroup.get('target').enable();          
        break;
      case 'value':
        this.itemGroup.get('value').enable();
        this.itemGroup.get('url').disable();
        this.itemGroup.get('target').disable();
        break;
      case 'disabled':
        this.itemGroup.get('value').disable();
        this.itemGroup.get('url').disable();
        this.itemGroup.get('target').disable();
        break;
    }
  }

}
