import { Component, OnInit, TemplateRef } from '@angular/core';

import { NotificationService } from '../../../services/notification.service';

@Component({
  selector: 'toast-notification',
  templateUrl: './toast-notification.component.html',
  styleUrls: ['./toast-notification.component.scss'],
  host: {'[class.ngb-toasts]': 'true'}
})
export class ToastNotificationComponent implements OnInit {

  constructor(public notificationService: NotificationService) { }

  ngOnInit() {
  }

  isTemplate(toast) { return toast.textOrTpl instanceof TemplateRef; }

}
