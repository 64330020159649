import { Component, OnInit, ViewEncapsulation, Input, OnDestroy, EventEmitter, Output, QueryList, ViewChildren } from '@angular/core';

import { Subscription, Subject } from 'rxjs';
import { NgbActiveModal, NgbPopover } from '@ng-bootstrap/ng-bootstrap';

import { Training } from '../../../classes/training';
import { PaginationSlide } from '../../../classes/pagination-slide';
import { NotificationService } from '../../../services/notification.service';
import { DialogService } from '../../../services/dialog.service';
import { AuthenticationService } from '../../../services/authentication.service';

@Component({
  selector: 'app-training-modal',
  templateUrl: './training-modal.component.html',
  styleUrls: ['./training-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TrainingModalComponent implements OnInit, OnDestroy {
  @Input() conversation: any;
  @Input() conversationSlide: PaginationSlide;
  @Output() private onUpdateResults = new EventEmitter<any>();
  @ViewChildren('popIntent') popIntent: QueryList<NgbPopover>;
  @ViewChildren('popEntity') popEntity: QueryList<NgbPopover>;

  onUpdateEntities: Subject<any> = new Subject<any>();

  subscriptions: Array<Subscription> = [];
  singleInteractionQuerying: any = {};

  interactions: Array<any> = [];
  entities: Array<any> = [];
  intents: Array<any>;

  filtersToggle: boolean = false;
  viewFilters: any = {
    parameters: true,
    contextIn: true,
    contextOut: true,
    response: true,
    rating: false
  };
  editedView: Array<boolean> = [];

  private popInteractionIndex: number;
  entityPopOffset: string;

  interactionsApproved: Training = new Training();

  constructor(public activeModal: NgbActiveModal, private dialogService: DialogService, private notificationService: NotificationService, private authenticationService: AuthenticationService) { }

  ngOnInit() {
    this.getInteractions();
    this.getIntents();

    this.conversationSlide.onMoveSlide.subscribe($event => {
      this.conversation = this.conversationSlide.currentItem;
      
      this.getInteractions();
    });
  }

  ngOnDestroy() {
    Object.keys(this.subscriptions).forEach((key: string) => {
      this.subscriptions[key].unsubscribe();
    });
  }

  closeModal() {
    this.activeModal.close();
  }

  getInteractions() {
    this.subscriptions['Interactions'] = this.dialogService.getInteractions(this.conversation.sessionId).subscribe((response: any) => {
      //console.log('[INTERACTIONS]', response);
      //this.interactions = response.data;
      this.interactions = this.processInteractionEntities(response.data);
    });
  }
  
  getEntities(intentName: string) {
    this.subscriptions['Entities'] = this.dialogService.getEntities({intentName: intentName}).subscribe((response: any) => {
      //console.log('[ENTITIES]', response);
      this.entities = response.singleResult.parameters;
    });
  }

  getIntents() {
    this.subscriptions['intents'] = this.dialogService.getIntents().subscribe((response: any) => {
      //console.log('[INTENTS]', response);
      this.intents = response.data;
    });
  }

  parseContextName(context: string): string {
    const contextPath = context.split('/');
    return contextPath[contextPath.length - 1];
  }

  processInteractionEntities(interactions: Array<any>): Array<any> {
    return interactions.reduce((accumulator, currentValue) => {
      if (currentValue.dfEntitiesJson) {
        for (const key in currentValue.dfEntitiesJson) {
          if (currentValue.dfEntitiesJson.hasOwnProperty(key) && (!currentValue.dfEntitiesJson[key] || typeof currentValue.dfEntitiesJson[key] !== 'string')) {
            delete currentValue.dfEntitiesJson[key];
          }
        }
      }
      accumulator.push(currentValue);
      return accumulator;
    }, [])
  }

  entitiesSort() {
    return 0;
  }

  setIntent(intent: any) {
    this.interactions[this.popInteractionIndex].dfIntentName = intent.name;
    /* this.interactions[this.popInteractionIndex].nluPayloadIntent = {
      id: intent.id,
      language: intent.language,
      name: intent.name
    }; */
    this.popIntent.toArray()[this.popInteractionIndex].close();
    this.interactions[this.popInteractionIndex].dfEntitiesJson = {};
    this.onUpdateEntities.next(this.interactions[this.popInteractionIndex].dfEntitiesJson);
  }

  setEntity(entityKey: string, entityEvent: any) {
    if (entityEvent.oldKey) delete this.interactions[this.popInteractionIndex].dfEntitiesJson[entityEvent.oldKey];
    
    this.interactions[this.popInteractionIndex].dfEntitiesJson[entityKey] = entityEvent.value;
    this.onUpdateEntities.next(this.interactions[this.popInteractionIndex].dfEntitiesJson);
    this.popEntity.toArray()[this.popInteractionIndex].close();
  }

  removeParameter(interaction: any, parameterKey: string) {
    delete interaction.dfEntitiesJson[parameterKey];
    this.onUpdateEntities.next(interaction.dfEntitiesJson);
  }

  async approveInteraction(interaction: any) {
    if ( interaction.edited && (!this.interactionsApproved.isNluPayloadSet(interaction.id) || !this.interactionsApproved.isOrchestratorPaylodSet(interaction.id)) ) {
      interaction.edited = false;
      return
    }
    if (this.interactionsApproved.isNluPayloadSet(interaction.id) || this.interactionsApproved.isOrchestratorPaylodSet(interaction.id)) {
      interaction.edited = false;
      this.interactionsApproved.removeInteraction(interaction.id);
    } else {
      interaction.edited = true;
      if (!this.isAssigned()) this.toggleAssignment();

      // if (!interaction.nluPayloadIntent) {
        this.singleInteractionQuerying[interaction.id] = false;
        interaction.nluPayloadIntent = await this.dialogService.getIntent(interaction.dfIntentName).toPromise().then((response: any) => {
          this.singleInteractionQuerying[interaction.id] = true;
          return response.singleResult;
        }, (error) => {
          console.log(error);
          this.singleInteractionQuerying[interaction.id] = true;
        });
      // }

      this.interactionsApproved.addInteraction(interaction);
    }
  }

  approveConversation() {
    //console.log('[INTERACTIONS_APPROVED]', this.interactionsApproved);
    const data = {
      dialogflowPayload: Object.values(this.interactionsApproved.nluPayload),
      orchestratorPayload: {
        idConversation: this.getConversationId(),
        archived: (Object.values(this.interactionsApproved.orchestratorPayload).length === this.interactions.length),
        messages: Object.values(this.interactionsApproved.orchestratorPayload)
      }
    };
    this.subscriptions['ApproveConversation'] = this.dialogService.approveConversation(data).subscribe((response: any) => {
      //console.log('[APPROVE_CONVERSATION]', response);
      this.activeModal.dismiss();
      this.notificationService.showToast(`Conversation <strong>${this.conversation.sessionId}</strong> correcty approved`, { type: 'success' });
    });
  }

  fallbackCount(): number {
    let counter = 0;
    this.interactions.forEach((interaction: any) => {
      if (interaction.fallback) counter++;
    });
    return counter;
  }

  isAssigned(): boolean {
    return !!(this.conversation.userEntity || {}).id;
  }

  isFallbackIntent(intentName: string): boolean {
    return this.intents && this.intents.some(intent => intent.name === intentName && intent.isFallback);
  }

  toggleAssignment() {
    if (this.isAssigned() && this.conversation.userEntity.id !== this.authenticationService.user.id) return;

    if (this.isAssigned()) {
      this.subscriptions['AssignConversation'] = this.dialogService.unassignConversation(this.getConversationId()).subscribe((response: any) => {
        this.notificationService.showToast(`Conversation correcty unassigned`, { type: 'success' });
        this.conversation.userEntity = null;
        this.onUpdateResults.emit();
      });
    } else {
      this.subscriptions['AssignConversation'] = this.dialogService.assignConversation({}, this.getConversationId(), this.authenticationService.user.id).subscribe((response: any) => {
        this.notificationService.showToast(`Conversation correcty assigned`, { type: 'success' });
        this.conversation.userEntity = { id: this.authenticationService.user.id, email: this.authenticationService.user.email };

        const data = { id: this.getConversationId() };
        this.dialogService.unarchiveConversation(data).toPromise().then(() => {
          this.onUpdateResults.emit();
        })
      });
    }
  }

  togglePopEntity($event: any, interactionIndex: number) {
    if (this.interactions[interactionIndex].edited) return;
    this.getEntities(this.interactions[interactionIndex].dfIntentName);
    
    this.popInteractionIndex = interactionIndex;
    this.entityPopOffset = `${$event.offset}px`;

    if (this.popEntity.toArray()[interactionIndex].isOpen()) {
      this.popEntity.toArray()[interactionIndex].close();
    } else {
      this.popEntity.toArray()[interactionIndex].open({$event});
    }
  }

  togglePopIntent(interactionIndex: number) {
    if (this.interactions[interactionIndex].edited) return;
    this.popInteractionIndex = interactionIndex;

    if (this.popIntent.toArray()[interactionIndex].isOpen()) {
      this.popIntent.toArray()[interactionIndex].close();
    } else {
      this.popIntent.toArray()[interactionIndex].open();
    }
  }

  private getConversationId() {
    return this.conversation.idConversation || this.conversation.id;
  }

}
