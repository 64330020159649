import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'market-connectors',
  templateUrl: './market-connectors.component.html',
  styleUrls: ['./market-connectors.component.scss']
})
export class MarketConnectorsComponent implements OnInit {
  @Input() connectors: Array<any>;
  @Output() onBuyProduct = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
  }

  buyItem() {
    this.onBuyProduct.emit();
  }

}
