import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, Validators } from '@angular/forms';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';

import { IntentService } from '../../../services/intent.service';

@Component({
  selector: 'app-intent-modal',
  templateUrl: './intent-modal.component.html',
  styleUrls: ['./intent-modal.component.scss']
})
export class IntentModalComponent implements OnInit {
  @Output() private onSaveIntent = new EventEmitter<any>();

  intentSubscription: Subscription;

  createIntentForm = this.fb.group({
    name: ['', Validators.required],
    inputContexts: this.fb.array([]),
    outputContexts: this.fb.array([]),
    webhookState: [false]
  });
  intentSubmitted: boolean = false;

  get contextsIn() { return this.createIntentForm.get('inputContexts') as FormArray }
  get contextsOut() { return this.createIntentForm.get('outputContexts') as FormArray }

  constructor(public activeModal: NgbActiveModal, private fb: FormBuilder, private intentService: IntentService) { }

  ngOnInit() {
  }

  saveIntent() {
    this.intentSubmitted = true;
    if (this.createIntentForm.invalid) return;

    this.intentSubscription = this.intentService.createIntent(this.createIntentForm.value).subscribe((response) => {
      this.activeModal.close();
      this.onSaveIntent.emit();
    });
  }

}
