import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'market-modules',
  templateUrl: './market-modules.component.html',
  styleUrls: ['./market-modules.component.scss']
})
export class MarketModulesComponent implements OnInit {
  @Input() modules: Array<any>;
  @Output() onBuyProduct = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
  }

  buyItem() {
    this.onBuyProduct.emit();
  }

}
