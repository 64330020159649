
export const environment = {
    production: false,
    API: {
        orchestrator_analysis: '/analysis/api/v1',
        orchestrator_configurator: '/configurator/api/v2',
        orchestrator_chat: '/chat/api/v2'
    },
    bot_embedded_url: 'https://chatbot-embedded-dot-tellya-stage.ew.r.appspot.com'
};
