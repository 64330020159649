import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormArray, FormGroup } from '@angular/forms';

import { Subscription } from 'rxjs';

@Component({
  selector: 'chips-item',
  templateUrl: './chips-item.component.html',
  styleUrls: ['./chips-item.component.scss']
})
export class ChipsItemComponent implements OnInit {
  @Input() element: FormGroup;
  @Input() formSubmitted: boolean;
  @Output() onAddChips = new EventEmitter<any>();

  iterableChanges: Subscription;

  get itemArray() { return this.element.get('items') as FormArray }

  constructor() { }

  ngOnInit() {
    this.iterableChanges = this.element.get('iterable').valueChanges.subscribe((value: boolean) => {
      if (value) {
        this.itemArray.controls.forEach((control: AbstractControl, index: number) => {
          if (index > 0) this.removeChips(index);
        })
      }
    });
  }

  ngOnDestroy() {
    this.iterableChanges.unsubscribe();
  }

  addChips() {
    this.onAddChips.emit();
  }

  removeChips(chipsIndex: number) {
    if (this.itemArray.length === 1) return;
    this.itemArray.removeAt(chipsIndex);
  }

}
