import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'input-context-field',
  templateUrl: './input-context-field.component.html',
  styleUrls: ['./input-context-field.component.scss']
})
export class InputContextFieldComponent implements OnInit {
  @Input() inputContexts: FormArray;
  @Input() formSubmitted: boolean;

  constructor(private fb: FormBuilder) { }

  ngOnInit() {
  }

  addContext($event) {
    $event.preventDefault();
    this.inputContexts.push(
      this.fb.group({ name: [$event.target.value, Validators.required] })
    );
    $event.target.value = '';
  }

}
