import { Component, OnInit, OnDestroy, Input } from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-user-settings-modal',
  templateUrl: './user-settings-modal.component.html',
  styleUrls: ['./user-settings-modal.component.scss']
})
export class UserSettingsModalComponent implements OnInit, OnDestroy {
  @Input() settingsMenu?: 'profile' | 'email' = 'profile';

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit() {
  }

  ngOnDestroy() {
  }

}
