import { Component, OnInit,  OnDestroy, Input, ViewChildren, ElementRef, QueryList } from '@angular/core';

import { Subscription } from 'rxjs';
//import { CarouselComponent } from 'ngx-owl-carousel-o';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { MonitoringService } from '../../../services/monitoring.service';
import { SupportModalComponent } from '../../../components/modals/support-modal/support-modal.component';
import { UserSettingsModalComponent } from '../../modals/user-settings-modal/user-settings-modal.component';
import { DesignService } from '../../../services/design.service';

declare let google: any;

@Component({
  selector: 'consumption-card',
  templateUrl: './consumption-card.component.html',
  styleUrls: ['./consumption-card.component.scss']
})
export class ConsumptionCardComponent implements OnInit, OnDestroy {
  //@ViewChild('owlCar', { static: false }) cardCarousel: CarouselComponent;
  @ViewChildren('billingSlide') private billingSlide: QueryList<ElementRef>;
  @Input() context: 'home' | 'billing';
  
  subscriptions: Array<Subscription> = [];
  enabledConnectors: Array<any> = [];
  promisesStatsClosed: boolean;

  cardCarouselOptions: OwlOptions = {
    dots: false,
    mouseDrag: false
  };

  constructor(private monitoringService: MonitoringService, private modalService: NgbModal, private designService: DesignService) { }

  ngOnInit() {
    google.charts.load('current', {'packages': ['corechart'] });
    
    if (this.context === 'home') {
      this.cardCarouselOptions = {...this.cardCarouselOptions,
        responsive: {
          0: { items: 1 },
          400: { items: 2 }
        }
      };
    } else if (this.context === 'billing') {
      this.cardCarouselOptions = {...this.cardCarouselOptions,
        items: 3
      };
    }
    
    this.subscriptions['JourneySubscription'] = this.designService.getSessionJourney().subscribe(() => {
      this.enabledConnectors = [];
      this.getConsumptions();
    });
  }

  ngOnDestroy() {
    Object.keys(this.subscriptions).forEach((key: string) => {
      this.subscriptions[key].unsubscribe();
    });
  }

  ngAfterViewInit() {
    this.billingSlide.changes.subscribe((element: ElementRef) => {

      if (!this.promisesStatsClosed) return;

      this.enabledConnectors.forEach((connector: any) => {
        switch (connector.chartId) {
          case 'dialogflow_chart':
            this.drawDialogflowChart(connector);
            break;
          case 'nlu_chart':
            this.drawNluChart(connector);
            break;
          case 'dlp_chart':
            this.drawDlpChart(connector);
            break;
        }
      })
    })
  }

  async getConsumptions() {
    this.promisesStatsClosed = false;

    let startDateSet = new Date(2020, 0, 1, 0,0,0).getTime();
    let endDateSet = new Date().setHours(23,59,59,999);
    const params = {
      connector: {
        dialogflow: 'dialogflow',
        nlu: 'naturallanguage',
        dlp: 'dlp'
      },
      days: 0,
      startDate: startDateSet,
      endDate: endDateSet
    }

    await this.getDialogflowRequests(params);
    await this.getNluRequests(params);
    await this.getDlpRequests(params);
    this.promisesStatsClosed = true;
  }

  getDialogflowRequests(params): Promise<void> {
    return this.monitoringService.getRequestStats(params.connector.dialogflow, params.days, params.startDate, params.endDate).toPromise().then((response: any) => {
      if (!response.total) return;

      this.enabledConnectors.push({...response, name: 'Google Dialogflow', chartId: 'dialogflow_chart'});
    })
  }

  getNluRequests(params): Promise<void> {
    return this.monitoringService.getRequestStats(params.connector.nlu, params.days, params.startDate, params.endDate).toPromise().then((response: any) => {
      if (!response.total) return;

      this.enabledConnectors.push({...response, name: 'Natural Language', chartId: 'nlu_chart'});
    })
  }
  
  getDlpRequests(params): Promise<void> {
    return this.monitoringService.getRequestStats(params.connector.dlp, params.days, params.startDate, params.endDate).toPromise().then((response: any) => {
      if (!response.total) return;

      this.enabledConnectors.push({...response, name: 'Data Loss Prevention', chartId: 'dlp_chart'});
    })
  }
  
  drawDialogflowChart(billingStats: any) {
    const totalPlaceholder = billingStats.total * (1 + 0.25);
    var remainingNegative: number;

    if(billingStats.remaining < 0) {
      remainingNegative = 0.01;
    }
    var data = google.visualization.arrayToDataTable([
      ['Connector', 'Requests'],
      ['', (totalPlaceholder/100)*25],
      ['Dialogflow', (remainingNegative) ? remainingNegative : billingStats.remaining  || billingStats.total],
      ['Others', billingStats.total - ((remainingNegative) ? remainingNegative : billingStats.remaining)]
    ]);

    var options = {
      pieHole: 0.8,
      legend: 'none',
      pieStartAngle: 135,
      tooltip: {trigger: 'none'},
      enableInteractivity: false,
      slices: {
        0: { color: 'transparent' },
        1: { color: this.getFillerColor(billingStats) },
        2: { color: '#dee2e6' }
      },
      pieSliceText: 'none',
      height: 300
    };

    var chart = new google.visualization.PieChart(document.getElementById('dialogflow_chart'));
    chart.draw(data, options);
  }

  drawNluChart(billingStats) {
    const totalPlaceholder = billingStats.total * (1 + 0.25);
    let remainingNegative: number;

    if(billingStats.remaining < 0) {
      remainingNegative = 0.01;
    }
    var data = google.visualization.arrayToDataTable([
      ['Connector', 'Requests'],
      ['', (totalPlaceholder/100)*25],
      ['Natural Language', (remainingNegative) ? remainingNegative : billingStats.remaining  || billingStats.total],
      ['Others', billingStats.total - ((remainingNegative) ? remainingNegative : billingStats.remaining)]
    ]);

    var options = {
      pieHole: 0.8,
      legend: 'none',
      pieStartAngle: 135,
      tooltip: {trigger: 'none'},
      enableInteractivity: false,
      slices: {
        0: { color: 'transparent' },
        1: { color: this.getFillerColor(billingStats) },
        2: { color: '#dee2e6' }
      },
      pieSliceText: 'none',
      height: 300
    };

    var chart = new google.visualization.PieChart(document.getElementById('nlu_chart'));
    chart.draw(data, options);
  }

  drawDlpChart(billingStats) {
    const totalPlaceholder = billingStats.total * (1 + 0.25);
    let remainingNegative: number;

    if(billingStats.remaining < 0) {
      remainingNegative = 0.01;
    }
    var data = google.visualization.arrayToDataTable([
      ['Connector', 'Requests'],
      ['', (totalPlaceholder/100)*25],
      ['Data Loss Prevention', (remainingNegative) ? remainingNegative : billingStats.remaining  || billingStats.total],
      ['Others', billingStats.total - ((remainingNegative) ? remainingNegative : billingStats.remaining)]
    ]);

    var options = {
      pieHole: 0.8,
      legend: 'none',
      pieStartAngle: 135,
      tooltip: {trigger: 'none'},
      enableInteractivity: false,
      slices: {
        0: { color: 'transparent' },
        1: { color: this.getFillerColor(billingStats) },
        2: { color: '#dee2e6' }
      },
      pieSliceText: 'none',
      height: 300
    };

    var chart = new google.visualization.PieChart(document.getElementById('dlp_chart'));
    chart.draw(data, options);
  }

  getFillerColor(billingStats: any): string {
    const lowRange = (billingStats.total/100)*33;
    const midRange = lowRange*2;
    if (billingStats.remaining <= lowRange) return '#dc3545';
    else if (billingStats.remaining > lowRange && billingStats.remaining <= midRange) return '#ffc107';
    else return '#28a745';
  }

  modalSupport() {
    const modalRef = this.modalService.open(SupportModalComponent, {
      size: 'lg'
    });
    modalRef.componentInstance.support = { requestType: 'Quota Increase' };
  }

  modalUserSettings() {
    const modalRef = this.modalService.open(UserSettingsModalComponent, {
      size: 'xl'
    });
    modalRef.componentInstance.settingsMenu = 'email';
  }

}
