import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-intent-settings',
  templateUrl: './intent-settings.component.html',
  styleUrls: ['./intent-settings.component.scss']
})
export class IntentSettingsComponent implements OnInit {
  
  constructor() { }

  ngOnInit() {
  }

}
