
export class Training {
    nluPayload: any = {};
    orchestratorPayload: any = {};

    constructor() {  }

    isApprovalsEmpty(): boolean {
        return Object.keys(this.nluPayload).length === 0 || Object.keys(this.orchestratorPayload).length === 0;
    }

    addInteraction(interaction: any) {
        this.setNluInteractionPaylod(interaction);
        this.setOrchestratorInteractionPaylod(interaction);
    }

    removeInteraction(interactionId: number) {
        delete this.nluPayload[interactionId];
        delete this.orchestratorPayload[interactionId];
    }

    setNluInteractionPaylod(interaction: any) {
        this.nluPayload[interaction.id] = {
            id: interaction.nluPayloadIntent.id,
            name: interaction.nluPayloadIntent.name,
            language: interaction.nluPayloadIntent.language,
            trainingPhrases: [
                {
                    type: 'EXAMPLE',
                    parts: this.processEntitiesPayload(interaction)
                }
            ]
        };
    }

    setOrchestratorInteractionPaylod(interaction: any) {
        this.orchestratorPayload[interaction.id] = {
            idMessage: interaction.id,
            message: interaction.userQuery
        };
    }

    isNluPayloadSet(interactionId: number): boolean {
        return (this.nluPayload[interactionId]);
    }

    isOrchestratorPaylodSet(interactionId: number): boolean {
        return (this.orchestratorPayload[interactionId]);
    }

    processEntitiesPayload(interaction: any) {
        //console.log('[INTERACTION]', interaction);
        //console.log('[ENTITIES]', interaction.dfEntitiesJson);
        //console.log('[USER_QUERY]', interaction.userQuery);

        if (Object.keys(interaction.dfEntitiesJson).length === 0) {
            return [
                {
                    text: interaction.userQuery,
                    entityType: '',
                    alias: ''
                }
            ];
        }
        
        let entityValues = Object.values(interaction.dfEntitiesJson).filter((value: string) => value.length > 0);
        const cleanPhrase = this.escapeRegExpChars(entityValues as Array<string>) as Array<string>;
        let regEx = new RegExp(`(${cleanPhrase.join('|')})`, 'gi');
        let parts = interaction.userQuery.split(regEx);
        //console.log('[ENTITIES_PARTS]', parts);

        return parts.reduce((accumulator = [], currentValue) => {
            if (currentValue.length > 0) {
                let entityAlias = this.getEntityAlias(interaction.dfEntitiesJson, currentValue);
                accumulator.push({
                    text: currentValue,
                    entityType: this.getParameterIntent(interaction.nluPayloadIntent, entityAlias).entityType || '',
                    alias: entityAlias
                });
            }
            return accumulator;
        }, []);
    }

    getEntityAlias(entities: any, resolvedValue: string) {
        let alias: string = '';
        Object.keys(entities).forEach((key) => {
            const cleanPhrase = this.escapeRegExpChars(resolvedValue) as string;
            if (entities[key].match(new RegExp(cleanPhrase, 'gi'))) {
                alias = key;
            }
        });
        return alias;
    }

    getParameterIntent(intent: any, parameterName: string) {
        return intent.parameters.find((parameter) => parameter.name === parameterName) || {};
    }

    escapeRegExpChars(phrase: string | Array<string>): string | Array<string> {
        if (Array.isArray(phrase)) {
            return phrase.reduce((accumulator, currentValue) => {
                accumulator.push(currentValue.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'));
                return accumulator;
            }, []);
        }
        return phrase.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    }
}
