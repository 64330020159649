import { Component, OnInit, OnDestroy, ViewChildren, QueryList } from '@angular/core';

import { Subscription } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { Pagination } from '../../../classes/pagination';
import { TableSortDirective } from '../../../directives/table-sort.directive';
import { DialogService } from '../../../services/dialog.service';
import { RatingModalComponent } from '../../../components/modals/rating-modal/rating-modal.component';

import { DesignService } from '../../../services/design.service';

declare let window: any;

@Component({
  selector: 'conversational-history',
  templateUrl: './conversational-history.component.html',
  styleUrls: ['./conversational-history.component.scss']
})
export class ConversationalHistoryComponent implements OnInit, OnDestroy {
  @ViewChildren(TableSortDirective) thead: QueryList<TableSortDirective>;

  interactionsSubscription: Subscription;
  journeySubscription: Subscription;
  exportSubscription: Subscription;
  interactionsPagination: Pagination = new Pagination();

  pageTarget: number = 1;
  currentPage: number = 1;
  pageSize: number = 10;
  totalPages: number;

  filter: any = {};
  interactions: Array<any> = [];

  constructor(private modalService: NgbModal, private dialogService: DialogService, private designService: DesignService) { }

  ngOnInit() {
    this.journeySubscription = this.designService.getSessionJourney().subscribe(() => {
      this.searchInteractions(1);
    });
  }

  ngOnDestroy() {
    this.interactionsSubscription.unsubscribe();
    this.journeySubscription.unsubscribe();
    if (this.exportSubscription instanceof Subscription) this.exportSubscription.unsubscribe();
  }

  updateDate($event) {
    if ($event.type === 'from') this.filter.fromDate = $event.date;
    if ($event.type === 'to') this.filter.toDate = $event.date;
  }

  viewConversation(sessionId: string) {
    const modalRef = this.modalService.open(RatingModalComponent, {
      size: 'lg'
    });
    modalRef.componentInstance.sessionId = sessionId;
  }

  searchInteractions(pageSelected: number) {
    this.interactionsPagination.onSelectPage(pageSelected);
    
    const params = {
      siebelId: this.filter.siebelId,
      startDate: this.filter.fromDate ? new Date(this.filter.fromDate.year, this.filter.fromDate.month - 1, this.filter.fromDate.day, 0, 0, 0).getTime() : null,
      endDate: this.filter.toDate ? new Date(this.filter.toDate.year, this.filter.toDate.month - 1, this.filter.toDate.day, 23, 59, 59).getTime() : null,
      dfIntentName: this.filter.intent || null,
      userQuery: this.filter.userQuery || null,
      sortBy: this.filter.sortBy || null
    };

    this.interactionsSubscription = this.dialogService.getInteractionHistory(params, this.interactionsPagination.getPageIndex(), this.interactionsPagination.pageSize).subscribe((response: any) => {
      //console.log('[INTERACTIONS]', response);
      this.interactions = response.data;
      this.interactionsPagination.updateTotals(response.totalElements);
    });
  }

  onSort({column, direction}: any) {
    this.thead.forEach((th: any) => {
      if (th.sortable !== column) {
        th.direction = '';
      }
    });

    this.filter.sortBy = (direction) ? `${column}:${direction}` : null;
    this.searchInteractions(this.interactionsPagination.currentPage);
  }

  exportCSV() {
    const params = {
      startDate: this.filter.fromDate ? new Date(this.filter.fromDate.year, this.filter.fromDate.month - 1, this.filter.fromDate.day, 0, 0, 0).getTime() : new Date(2000,1,1,0,0,0).getTime(),
      endDate: this.filter.toDate ? new Date(this.filter.toDate.year, this.filter.toDate.month - 1, this.filter.toDate.day, 23, 59, 59).getTime() : new Date(2100,1,1,0,0,0).getTime()
    };
    this.exportSubscription = this.dialogService.getCsvExport(params).subscribe((response) => {
      console.log('[EXPORT]', response);

      const blob = new Blob([response], { type: 'text/csv;charset=utf-8;' });
      /* if (navigator.msSaveBlob) {
        navigator.msSaveBlob(blob, 'Catalog.csv');
      } else { */
        let link = document.createElement('a');
        if (link.download !== undefined) {
          const url = URL.createObjectURL(blob);
          link.setAttribute('href', url);
          link.setAttribute('download', 'Export.csv');
          link.style.visibility = 'hidden';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      //}
    });
  }
}
