import { Component, OnInit,OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { DashboardService } from '../../../services/dashboard.service';
import { DesignService } from '../../../services/design.service';

@Component({
  selector: 'training-card',
  templateUrl: './training-card.component.html',
  styleUrls: ['./training-card.component.scss']
})

export class TrainingCardComponent implements OnInit, OnDestroy {
  subscriptions: Array<Subscription> = [];
  conversationsTrainingTotalBookmarked: number;
  conversationsTrainingTotalNotAnalyzed: number;
  conversationsTrainingTotalFallbacks: number;
  conversationsTrainingPercentageIntents: number;
  conversationsTrainingPercentagAgentAutonomy: number;
  
  constructor(private dashboardservice: DashboardService, private designService: DesignService) { }

  ngOnInit() {
    this.subscriptions['JourneySubscription'] = this.designService.getSessionJourney().subscribe(() => {
      this.getConversationsTrainingStatistics();
    });
  }

  ngOnDestroy() {
    Object.keys(this.subscriptions).forEach((key: string) => {
      this.subscriptions[key].unsubscribe();
    });
  }

  getConversationsTrainingStatistics() {   
    const params = {
      startDate: new Date(2020,0,1, 0,0,0).getTime(),
      endDate: new Date().setHours(23,59,59,999),
      tags:  null
    };

    this.getConversationTrainingTotalBookmarked(params);
    this.getConversationTrainingTotalNotAnalyzed(params);
    this.getConversationTrainingTotalFallbacks(params);
    this.getConversationsTrainingPercentageIntents(params);
    this.getConversationsTrainingPercentagAgentAutonomy(params);
  }

  getConversationTrainingTotalBookmarked(params: any) {
    this.subscriptions['ConversationsTrainingTotalBookmarked'] = 
    this.dashboardservice.getTotalBookmarked(params.startDate, params.endDate)
    .subscribe((response: any) => {
      this.conversationsTrainingTotalBookmarked = response.singleResult;
    })
  }

  getConversationTrainingTotalNotAnalyzed(params: any) {
    this.subscriptions['ConversationsTrainingTotalNotAnalyzed'] = 
    this.dashboardservice.getTotalNotAnalyzed(params.startDate, params.endDate)
    .subscribe((response: any) => {
      this.conversationsTrainingTotalNotAnalyzed = response.singleResult;
    })
  }

  getConversationTrainingTotalFallbacks(params: any) {
    this.subscriptions['ConversationsTrainingTotalFallbacks'] = 
    this.dashboardservice.getTotalFallback(params.startDate, params.endDate)
    .subscribe((response: any) => {
      this.conversationsTrainingTotalFallbacks = response.singleResult;
    })
  }

  getConversationsTrainingPercentagAgentAutonomy(params: any) {
    this.subscriptions['ConversationsTrainingPercentagAgentAutonomy'] = this.dashboardservice.getPercentageAgentAutonomy(params.startDate, params.endDate).subscribe((response: any) => {
      this.conversationsTrainingPercentagAgentAutonomy = (isNaN(response.singleResult.percAgentAutonomy)) ? 0 : response.singleResult.percAgentAutonomy;
    })
  }

  getConversationsTrainingPercentageIntents(params: any) {
    this.subscriptions['ConversationsTrainingPercentageIntents'] = this.dashboardservice.getPercentageTrustedIntents(params.startDate, params.endDate).subscribe((response: any) => {
      this.conversationsTrainingPercentageIntents = (isNaN(response.singleResult.percTrusted)) ? 0 : response.singleResult.percTrusted;
    })
  }

}
