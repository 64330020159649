import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';

import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DesignService {
  onChangeJourney: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  onUpdateJourneys: Subject<any> = new Subject<any>();
  public sessionJourney: any;

  constructor(private http: HttpClient) { }

  getSessionJourney(): Observable<any> {
    return this.onChangeJourney.asObservable().pipe(
      filter(journey => journey != null)
    );
  }

  setSessionJourney(journey: any) {
    this.sessionJourney = journey;
    this.onChangeJourney.next(journey);
  }

  updateJourneys(): Observable<any> {
    return this.onUpdateJourneys.asObservable();
  }

  changesJourneys() {
    this.onUpdateJourneys.next();
  }

  getJourneysList(): Observable<any> {
    return this.http.get(`${environment.API.orchestrator_configurator}/journey`);
  }

  getJourney(journeyId: any): Observable<any> {
    return this.http.get(`${environment.API.orchestrator_configurator}/journey/${journeyId}`);
  }

  createJourney(data: any): Observable<any> {
    return this.http.post(`${environment.API.orchestrator_configurator}/journey`, data);
  }

  editJourney(data: any): Observable<any> {
    return this.http.put(`${environment.API.orchestrator_configurator}/journey`, data);
  }

  deleteJourney(journeyId: number): Observable<any> {
    const httpOptions = {
      params: new HttpParams()
        .set('journeyId', journeyId.toString())
    };
    return this.http.delete(`${environment.API.orchestrator_configurator}/journey`, httpOptions);
  }

  getConnectorsList(): Observable<any> {
    return this.http.get(`${environment.API.orchestrator_configurator}/`);
  }

  getConnector(connectorName: string, connectorId: number): Observable<any> {
    return this.http.get(`${environment.API.orchestrator_configurator}/${connectorName}/${connectorId}`);
  }

  addConnector(connectorName: string, data: any): Observable<any> {
    return this.http.post(`${environment.API.orchestrator_configurator}/${connectorName}`, data);
  }

  removeConnector(connectorName: string, connectorId: number): Observable<any> {
    const httpOptions = {
      params: new HttpParams()
        .set('connectorId', connectorId.toString())
    };
    return this.http.delete(`${environment.API.orchestrator_configurator}/${connectorName}`, httpOptions);
  }

  editConnector(connectorName: string, data: any, params?: any): Observable<any> {
    const httpOptions = {
      params: new HttpParams()
    };

    if (params && params.clearAnalytics) httpOptions.params = httpOptions.params.append('clearAnalytics', params.clearAnalytics);

    return this.http.put(`${environment.API.orchestrator_configurator}/${connectorName}`, data, httpOptions);
  }

  getAgentsBot(): Observable<any> {
    return this.http.get(`${environment.API.orchestrator_configurator}/agentbot/getAll`);
  }

  changeFulfilmentAgent(data: any, params: any): Observable<any> {
    const httpOptions = {
      params: new HttpParams()
        .set('agentId', params.agentId)
        .set('journeyId', params.journeyId)
    }
    return this.http.put(`${environment.API.orchestrator_configurator}/journey/changeAgent`, data, httpOptions);
  }
  
  getDlpInfoTypes(): Observable<any> {
    return this.http.get(`${environment.API.orchestrator_configurator}/dlp/getAllInfoType`);
  }

  getTranslateLanguages(): Observable<any> {
    return this.http.get(`${environment.API.orchestrator_configurator}/translate/languages`);
  }

}
