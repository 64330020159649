import { Component, OnInit, OnDestroy } from '@angular/core';

import { Subscription } from 'rxjs';

import { AnalyticsService } from '../../../services/analytics.service';
import { DashboardService } from '../../../services/dashboard.service';
import { DesignService } from '../../../services/design.service';

declare let google: any;

@Component({
  selector: 'overview-card',
  templateUrl: './overview-card.component.html',
  styleUrls: ['./overview-card.component.scss']
})
export class OverviewCardComponent implements OnInit, OnDestroy {

  subscriptions: Array<Subscription> = [];
  conversationsStatistics: any;
  conversationsStatisticsTotal: number;
  interactionsAverageSession: number;
  interactionsAverageDuration: number;

  currentDate = new Date();
  
  constructor(private dashboardservice: DashboardService, private designService: DesignService, private analyticsServcie: AnalyticsService) { }

  ngOnInit() {
    google.charts.load('current', { 'packages': ['corechart'] });

    this.subscriptions['JourneySubscription'] = this.designService.getSessionJourney().subscribe(() => {
      this.getStatistics();
    });
  }

  ngOnDestroy() {
    Object.keys(this.subscriptions).forEach((key: string) => {
      this.subscriptions[key].unsubscribe();
    });
  }

  getStatistics() {
    const params = {
      startDate: new Date(this.currentDate.getFullYear(), this.currentDate.getMonth(), this.currentDate.getDate()-7, 0,0,0).getTime(),
      endDate: this.currentDate.setHours(23,59,59,999),
      tags:  null
    };
    this.getConversationsStatistics(params);
    
    const paramsAvg = {
      startDate: new Date(2020,0,1, 23,59,59).getTime(),
      endDate: this.currentDate.setHours(23,59,59,999),
      tags:  null
    };
    this.getInteractionsAverageDuration(paramsAvg);
    this.getInteractionsAverageSession(paramsAvg);
    this.getConversationsStatisticsTotal(paramsAvg);
  }

  getConversationsStatistics(params: any) {
    this.subscriptions['ConversationsStatistics'] = 
    this.dashboardservice.getConversationsStatistics(params.startDate, params.endDate, params.tags)
    .subscribe((response: any) => {
      this.conversationsStatistics = {
        total: response.multiObject.total.value,
        chartData: this.processChartData(response.multiObject.line.chart)
      };
      this.drawSessionsChart();
    })
  }

  getConversationsStatisticsTotal(params: any) {
    this.subscriptions['ConversationsStatistics'] = 
    this.dashboardservice.getConversationsStatistics(params.startDate, params.endDate, params.tags)
    .subscribe((response: any) => {
      this.conversationsStatisticsTotal = response.multiObject.total.value;
    })
  }

  getInteractionsAverageDuration(params: any) {
    this.subscriptions['InteractionsAverageDuration'] = 
    this.dashboardservice.getInteractionsAverageDuration(params.startDate, params.endDate, params.tags)
    .subscribe((response: any) => {
      this.interactionsAverageDuration = response.singleResult.value;
    });
  }

  getInteractionsAverageSession(params: any) {
    this.subscriptions['InteractionsAverageSession'] = 
    this.dashboardservice.getInteractionsAverageSession(params.startDate, params.endDate, params.tags)
    .subscribe((response: any) => {
      this.interactionsAverageSession = response.singleResult.value;
    })
  }

  drawSessionsChart() {
    google.charts.setOnLoadCallback(() => {
      let data = google.visualization.arrayToDataTable(this.conversationsStatistics.chartData);

      let options = {
        curveType: 'none',
        legend: { position: 'none' },
        hAxis: {
          format: 'dd MMM y',
        },
        vAxis: {
          viewWindow: this.analyticsServcie.getAxisScale(this.conversationsStatistics.chartData)
        }
      };

      let chart = new google.visualization.LineChart(document.getElementById('chart_sessions'));

      chart.draw(data, options);
    });
  }

  processChartData(data: any): Array<Array<any>> {
    let chartData: Array<Array<any>> = [];
    if (!data) return chartData;

    chartData.push(['Date', 'Sessions']);
    data.forEach((date: any) => {
      chartData.push([new Date(date.currentDate), date.value]);
    });

    return chartData;
  }

}
