import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, Validators, FormArray } from '@angular/forms';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { Chatbot } from '../../../classes/chatbot';

@Component({
  selector: 'app-chat-settings-modal',
  templateUrl: './chat-settings-modal.component.html',
  styleUrls: ['./chat-settings-modal.component.scss']
})
export class ChatSettingsModalComponent implements OnInit {
  @Input() botSession: Chatbot;

  settingsForm = this.fb.group({
    customData: this.fb.array([]),
    outputAudio: [false]
  });

  get sessionData() { return this.settingsForm.get('customData') as FormArray }

  settingsSubmitted: boolean = false;

  constructor(public activeModal: NgbActiveModal, private fb: FormBuilder) { }

  ngOnInit() {
    this.settingsForm.get('outputAudio').setValue(this.botSession.outputAudio);

    if (Object.keys(this.botSession.sessionUserData).length) {
      this.processFormArray(this.botSession.sessionUserData);
    }
  }

  processFormArray(formData: any) {
    for (const key in formData) {
      if (formData.hasOwnProperty(key)) {
        this.sessionData.push(this.fb.group(
          { key: key, value: formData[key] }
        ));
      }
    }
  }

  addCustomData() {
    this.sessionData.push(
      this.fb.group({
        key: ['', Validators.required],
        value: ['', Validators.required]
      })
    );
  }

  saveSettings() {
    //console.log('[SUBMIT]', this.settingsForm);
    this.settingsSubmitted = true;
    if (this.settingsForm.invalid) return;

    const userData = this.sessionData.value.reduce((userData, customSetting) => {
      userData[customSetting.key] = customSetting.value;
      return userData;
    }, {});  

    const sessionSettings = {
      sessionUserData: userData,
      outputAudio: this.settingsForm.get('outputAudio').value
    };

    console.log(sessionSettings);

    this.activeModal.close();
    this.botSession.restoreChatbot(sessionSettings);
  }

}
