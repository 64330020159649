import { Component, OnInit, OnDestroy } from '@angular/core';

import { Subscription } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { Pagination } from '../../../classes/pagination';
import { NotificationService } from '../../../services/notification.service';
import { AdminService } from '../../../services/admin.service';
import { UserModalComponent } from '../../modals/user-modal/user-modal.component';
import { AuthenticationService } from '../../../services/authentication.service';

@Component({
  selector: 'access-management',
  templateUrl: './access-management.component.html',
  styleUrls: ['./access-management.component.scss']
})
export class AccessManagementComponent implements OnInit, OnDestroy {
  subscriptions: Array<Subscription> = [];
  usersPagination: Pagination = new Pagination();

  users: Array<any> = [];

  constructor(private notificationService: NotificationService, private modalService: NgbModal, private adminService: AdminService, private authenticationService: AuthenticationService) { }
  
  ngOnInit() {
    this.getUsers(1);

    this.subscriptions['ChangeAccount'] = this.authenticationService.onChangeSessionAccount().subscribe(() => {
      this.getUsers(1);
    });
  }

  ngOnDestroy() {
    Object.keys(this.subscriptions).forEach((key: string) => {
      this.subscriptions[key].unsubscribe();
    });
  }

  getUsers(pageSelected: number) {
    this.usersPagination.onSelectPage(pageSelected);

    this.subscriptions['Users'] = this.adminService.getUsers(this.usersPagination.getPageIndex(), this.usersPagination.pageSize, this.authenticationService.user.accountId).subscribe((response: any) => {
      //console.log('[USERS]', response);
      this.users = response.content;
      this.usersPagination.updateTotals(response.totalElements);
    });
  }

  modalUser(userId?: number) {
    const modalRef = this.modalService.open(UserModalComponent, {
      size: 'lg'
    });
    modalRef.componentInstance.userId = userId || null;
    modalRef.componentInstance.onSaveUser.subscribe($event => this.getUsers(this.usersPagination.currentPage));
  }

  removeUser(user: any) {
    this.subscriptions['ConfirmModalRemove'] = this.notificationService.openModal({
      title: 'Confirm remove User',
      message: `Are you sure to delete the user with E-Mail <strong>${user.email}</strong>?`,
      choice: 'multi'
    }).subscribe((confirm: boolean) => {
      if (!confirm) return;

      this.subscriptions['DeleteUser'] = this.adminService.deleteUser(user.id).subscribe((response: any) => {
        this.notificationService.showToast(`User with E-Mail <strong>${user.email}</strong> has been correctly removed`, { type: 'success' });
        this.getUsers(1);
      });
    });
  }

}
