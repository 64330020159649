
import { Edge, Node, Layout, ClusterNode } from '@swimlane/ngx-graph';
import { Subject } from 'rxjs';

export class IntentFlow {
    items: Object;

    nodes: Array<Node> = [];
    links: Array<Edge> = [];
    clusters: Array<ClusterNode> = [];
    flowIds: Array<Array<string>> = [];
    layoutSettings = { orientation: 'TB' };

    zoomToFit$: Subject<boolean> = new Subject();
    update$: Subject<boolean> = new Subject();
    center$: Subject<boolean> = new Subject();

    constructor(data: Object) {
        this.items = data;
        
        //console.log('[DATA]', this.items);

        this.initChart();

        /* this.items.forEach((flowItems: Array<any>, flowIndex: number) => {
            this.flowIds.push([]);
            this.drawFlow(flowItems, flowIndex);

            this.addCluster(`cluster_${flowIndex}`, `Flow id ${flowIndex}`, this.flowIds[flowIndex]);
        }); */
    }

    initChart() {
        Object.keys(this.items).forEach((flowKey: string, flowIndex: number) => {
            this.flowIds.push([]);
            this.drawFlow(this.items[flowKey], flowIndex);

            this.addCluster(`cluster_${this.items[flowKey]}`, `Flow id ${this.items[flowKey]}`, this.flowIds[flowIndex]);
        });

        this.updateChart();

        // console.log('[NODES]', this.nodes);
        // console.log('[LINKS]', this.links);
        // console.log('[CLUSTERS]', this.clusters);
    }

    drawFlow(items: Array<any>, flowIndex: number) {
        items.forEach((item: any, itemIndex: number) => {
            this.addNode(item, flowIndex, itemIndex);
        });
    }

    addNode(item: any, flowIndex: number, itemIndex: number) {
        this.nodes.push({
            id: item.id.toString(),
            label: item.name,
            data: item
        });
        this.flowIds[flowIndex].push(item.id.toString());

        if (item.childs && item.childs.length) {
            item.childs.forEach(childItem => {
                this.addLink(item.id.toString(), childItem.toString());
            });
        }

        /* if (item.responses && item.responses.length) {
            this.processResponses(item, flowIndex, itemIndex);
        } */
    }

    addLink(spurceId: string, targetId: string) {
        this.links.push({
            source: spurceId,
            target: targetId
        });
    }

    addCluster(clusterId: string, clusterLabel: string, childIds: Array<string>) {
        this.clusters.push({
            id: clusterId,
            label: clusterLabel,
            childNodeIds: childIds
        });
    }

    /* getTargetId(flowIndex: number, itemIndex: number): string {
        return this.items[flowIndex][itemIndex + 1].id.toString();
    }

    processResponses(parentNode: any, flowIndex: number, itemIndex: number) {
        parentNode.responses.forEach((item: any) => {
            this.nodes.push({
                id: `response_${flowIndex}-${itemIndex}`,
                label: item.type,
                data: { ...item, tile: 'response' }
            });
            this.flowIds[flowIndex].push(`response_${flowIndex}-${itemIndex}`);

            this.links.push({
                source: parentNode.id.toString(),
                target: `response_${flowIndex}-${itemIndex}`
            });

            if (this.items[flowIndex][itemIndex + 1]) {
                this.links.push({
                    source: `response_${flowIndex}-${itemIndex}`,
                    target: this.getTargetId(flowIndex, itemIndex)
                }); 
            }
        });
    } */

    resetChart() {
        this.nodes = [];
        this.links = [];
        this.clusters = [];
        this.flowIds = [];

        this.initChart();
    }

    fitChart() {
        this.zoomToFit$.next(true);
    }

    updateChart() {
        this.update$.next(true);
    }

    centerChart() {
        this.center$.next(true);
    }

}
