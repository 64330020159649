import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fullTextSearch'
})
export class FullTextSearchPipe implements PipeTransform {

  transform(items: Array<any>, value: string, target: string): Array<any> {
    if (!value) {
      return items;
    }
    if (target) return items.filter(item => item[target].toLowerCase().indexOf(value.toLowerCase()) !== -1);
    else return items.filter(item => item.toLowerCase().indexOf(value.toLowerCase()) !== -1);
  }

}
