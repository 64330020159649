import { Component, OnInit, Input, Output, ViewChild, EventEmitter, OnDestroy, ViewEncapsulation } from '@angular/core';
import { NgForm } from '@angular/forms';

import { debounceTime, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { NgbActiveModal, NgbPopover } from '@ng-bootstrap/ng-bootstrap';

import { DesignService } from '../../../../services/design.service';
import { DialogService } from '../../../../services/dialog.service';

@Component({
  selector: 'app-generic-rule-modal',
  templateUrl: './generic-rule-modal.component.html',
  styleUrls: ['./generic-rule-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class GenericRuleModalComponent implements OnInit, OnDestroy {
  @Input() private journey: any;
  @Input() connector: any;
  @Output() private onSaveConnector = new EventEmitter<any>();
  @ViewChild('ruleForm', { static: true }) ruleForm: NgForm;

  subscriptions: Object = {};

  connectors: Array<any>
  intents: Array<any>;
  //private intentsSelected: Array<any>;
  connectorToRun: string;

  typeSuggestions = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      map(term => term === '' ? [] : ['$intentName', '$actionName'].filter(type => type.toLowerCase().indexOf(term.toLowerCase()) > -1))
    )
  
  constructor(public activeModal: NgbActiveModal, private designService: DesignService, private dialogService: DialogService) { }

  ngOnInit() {
    if (!this.connector) {
      this.connector = {}
    } else {
      /* if (this.connector.ifFieldType === '$intentName') {
        let intentsSplitted = this.connector.hasValue.split(',');
        this.intentsSelected = intentsSplitted;
      } */
      if (this.connector.connectors && this.connector.connectors.length) {
        this.connectorToRun = this.connector.connectors[0].productCode;
      }
    }
    this.getIntents();
  }

  ngOnDestroy() {
    Object.keys(this.subscriptions).forEach((key: string) => {
      this.subscriptions[key].unsubscribe();
    });
  }

  getIntents() {
    const params = {
      journeyApiKey: this.journey.apiKey
    };

    this.subscriptions['Intents'] = this.dialogService.getIntents(params).subscribe((response: any) => {
      //console.log('[INTENTS]', response);
      this.intents = response.data.sort((a, b) => {
        if (a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1;
        } else if (a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1;
        } else {
          return 0;
        }
      });
    });
  }

  toggleRuleIntent(intentName: string) {
    if (this.isIntentSelected(intentName)) {
      this.connector.intentNames = this.connector.intentNames.filter(item => item !== intentName);
    } else {
      if (!this.connector.intentNames) this.connector.intentNames = [];
      this.connector.intentNames.push(intentName);
    }
  }

  isIntentSelected(intentName: string): boolean {
    return (this.connector.intentNames || []).some(item => item === intentName);
  }

  selectAll() {    
    if ( this.connector.intentNames && (this.connector.intentNames.length === this.intents.length) ) {
      this.connector.intentNames = [];
    } else {
      this.connector.intentNames = [];
      this.intents.forEach(intent => this.connector.intentNames.push(intent.name));
    }
  }

  typeChange() {
    if (this.connector.ifFieldType === '$intentName') this.connector.present = false;
  }

  saveConnector() {
    if (this.ruleForm.invalid) return;

    let data = {...this.connector, journeyId: this.journey.id };
    if (!this.connector.present && this.connector.ifFieldType === '$intentName') data.hasValue = null;
    else data.intentNames = null;
    
    if (this.connector.id) {
      if (data.connectors) delete data.connectors;

      this.subscriptions['Connector'] = this.designService.editConnector('rule', data).subscribe(() => {
        this.activeModal.close();
        this.onSaveConnector.emit();
      });
    } else {
      this.subscriptions['Connector'] = this.designService.addConnector('rule', data).subscribe((response: any) => {
        this.activeModal.close();
        this.onSaveConnector.emit({
          connector: { productCode: this.connectorToRun, parentId: response.id }
        });
      });
    }
  }

  getPlaceholder(popIntent: NgbPopover): string {
    if (popIntent.isOpen()) {
      return 'Search Intents';
    } else {
      return `${(this.connector.intentNames || []).length} intents selected`;
    }
  }

}
