import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ChatService {

  constructor(private http: HttpClient) { }

  sendMessage(apiKey: string, data: any): Observable<any> {
    const httpOptions = {
      params: new HttpParams()
        .set('cleanResponse', 'false')
        .set('debugMode', 'true')
    };

    return this.http.post(`${environment.API.orchestrator_chat}/orchestrate/${apiKey}`, data, httpOptions);
  }

  sendFulfillmentMessage(apiKey: string, data: any): Observable<any> {
    const httpOptions = {
      params: new HttpParams()
        
    };
    return this.http.post(`${environment.API.orchestrator_chat}/fulfillmentIntegration/${apiKey}`, data, httpOptions);
  }

}
