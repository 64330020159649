import { Injectable, OnDestroy } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';

import { Observable, Subscription } from 'rxjs';

import { LicenseService } from '../services/license.service';
import { NotificationService } from '../services/notification.service';

@Injectable({
  providedIn: 'root'
})
export class LicenseRestrictionGuard implements CanActivate {
  licenseSubscription: Subscription;

  constructor(private router: Router, private licenseService: LicenseService, private notificationService: NotificationService) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return new Promise<boolean>((resolve, reject) => {
      this.licenseSubscription = this.licenseService.getSessionLicense().subscribe(() => {
        if (this.licenseService.isPurchased(next.data.licenseRestriction)) {
          resolve(true);
        } else {        
          this.router.navigate(['']);
          this.notificationService.showToast('Upgrade your license to access this module', { type: 'warning' });
          reject(false);
          this.licenseSubscription.unsubscribe();
        }
      })
    })
  }
  
}
