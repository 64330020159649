import { Component, OnInit, OnDestroy, ViewChild} from '@angular/core';
import { NgForm } from '@angular/forms';

import { Subscription } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { AdminService } from '../../../services/admin.service';
import { Pagination } from '../../../classes/pagination';
import { TopicsModalComponent } from '../../modals/topics-modal/topics-modal.component';
import { AuthenticationService } from '../../../services/authentication.service';

@Component({
  selector: 'training-team',
  templateUrl: './training-team.component.html',
  styleUrls: ['./training-team.component.scss']
})
export class TrainingTeamComponent implements OnInit, OnDestroy {
  @ViewChild('trainerFilter', { static: true }) private trainerFilter: NgForm;

  accountSubscription: Subscription;
  trainersSubscriptions: Subscription;
  trainers: Array<any> = [];
  trainersPagination: Pagination = new Pagination();

  constructor(private modalService: NgbModal, private adminService: AdminService, private authenticationService: AuthenticationService) { }
  
  ngOnInit() {
    this.getTrainers();

    this.accountSubscription = this.authenticationService.onChangeSessionAccount().subscribe(() => {
      this.getTrainers();
    });
  }

  ngOnDestroy() {
    this.accountSubscription.unsubscribe();
    if (this.trainersSubscriptions instanceof Subscription) this.trainersSubscriptions.unsubscribe();
  }

  getTrainers() {
    this.trainersSubscriptions = this.adminService.getTrainerTags().subscribe((response: any) => {
      this.trainers = response;
    });
  }

  editTopics(trainer: any) {
    const modalRef = this.modalService.open(TopicsModalComponent, {
      size: 'lg'
    });
    modalRef.componentInstance.trainer = trainer;
    modalRef.componentInstance.onUpdateTags.subscribe($event => this.getTrainers());
  }
}