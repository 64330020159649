import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, ViewEncapsulation } from '@angular/core';
import { FormArray, FormBuilder, Validators } from '@angular/forms';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';

import { AnalyticsService } from '../../../../services/analytics.service';
import { IntentService } from '../../../../services/intent.service';
import { DesignService } from '../../../../services/design.service';

@Component({
  selector: 'app-dialogflow-connector-modal',
  templateUrl: './dialogflow-connector-modal.component.html',
  styleUrls: ['./dialogflow-connector-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DialogflowConnectorModalComponent implements OnInit, OnDestroy {
  @Input() private journey: any;
  @Input() connector: any;
  @Output() private onSaveConnector =  new EventEmitter<any>();

  subscriptions: Object = {};

  agents: Array<any>;
  formEditMode: 'import' | 'export' = 'export';
  importedFile: string;

  moveStep: Subject<any> = new Subject<any>();

  dialogflowPhases: any = {
    steps: [
      { name: 'General settings', submitted: false }
    ],
    current: 0
  };

  dialogflowForm = this.fb.group({
    steps: this.fb.array([
      this.fb.group({
        //name: ['', Validators.required],
        agentBot: ['', Validators.required]
      })
    ])
  });

  get stepsForm() { return this.dialogflowForm.get('steps') as FormArray }
  
  get stepsOne() { return this.stepsForm.at(0) }

  constructor(public activeModal: NgbActiveModal, private designService: DesignService, private fb: FormBuilder, private intentService: IntentService, private analyticsService: AnalyticsService) { }

  ngOnInit() {
    if (this.connector.id) {
      this.stepsForm.patchValue([
        { agentBot: this.connector.agentBot }
      ]);
      this.setEditMode();
    }
    this.getAgentsBot();
  }

  ngOnDestroy() {
    Object.keys(this.subscriptions).forEach((key: string) => {
      this.subscriptions[key].unsubscribe();
    });
  }

  getAgentsBot() {
    this.subscriptions['AgentsBot'] = this.designService.getAgentsBot().subscribe((response: any) => {
      this.agents = response;
    });
  }

  setEditMode() {
    if (this.dialogflowPhases.steps[2] && this.stepsForm.at(2)) {
      this.dialogflowPhases.steps.splice(2, 1);
      this.stepsForm.removeAt(2);
    }
    if (this.dialogflowPhases.steps[1] && this.stepsForm.at(1)) {
      this.dialogflowPhases.steps.splice(1, 1);
      this.stepsForm.removeAt(1);
    }

    if (this.formEditMode === 'export') {
      this.stepsOne.get('agentBot').enable();

      this.dialogflowPhases.steps.push({ name: 'Export intent data', submitted: false });
      this.dialogflowPhases.steps.push({ name: 'Export history', submitted: false });

      this.stepsForm.push(this.fb.group({}));
      this.stepsForm.push(this.fb.group({ clearAnalytics: [''] }));
    } else if (this.formEditMode === 'import') {
      this.stepsOne.get('agentBot').disable();

      this.dialogflowPhases.steps.push({ name: 'Import data', submitted: false });
      this.stepsForm.push(this.fb.group({}));
    }
  }

  nextStep() {
    this.moveStep.next('next');
  }

  saveConnector($event?) {
    let data;
    if ($event) {
      data = { ...this.connector, ...$event, journeyId: this.journey.id };
    } else {
      if (this.dialogflowForm.invalid) return;
      data = { agentBot: this.stepsOne.get('agentBot').value, journeyId: this.journey.id };
    }

    if (this.connector.id) {
      const params = {
        clearAnalytics: this.stepsForm.at(2).get('clearAnalytics').value || 'false',
      };
      this.subscriptions['Connector'] = this.designService.editConnector('dialogFlow', data, params).subscribe(() => {
        this.activeModal.close();
        this.onSaveConnector.emit();
      });
    } else {
      this.subscriptions['Connector'] = this.designService.addConnector('dialogFlow', data).subscribe(() => {
        this.activeModal.close();
        this.onSaveConnector.emit();
      });
    }
  }

  exportIntents() {
    const params = {
      journeyApiKey: this.journey.apiKey
    };
    this.subscriptions['ExportIntents'] = this.intentService.exportIntentsData(params).subscribe();
  }

  importIntents(data: FileList) {
    const params = {
      journeyApiKey: this.journey.apiKey
    };
    this.subscriptions['ImportIntents'] = this.intentService.importIntentsData(data, params).subscribe(() => {
      this.importedFile = data[0].name;
    });
  }

  exportHistory() {
    const params = {
      journeyApiKey: this.journey.apiKey
    };
    this.subscriptions['ExportHistory'] = this.analyticsService.exportAnalyticsData(params).subscribe();
  }

  compareAgent(agent1: any, agent2: any) {
    return agent1.id === agent2.id;
  }

}
