import { Component, OnInit, OnDestroy, ViewChild, Input } from '@angular/core';
import { NgForm } from '@angular/forms';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';

import { AdminService } from '../../../services/admin.service';
import { NotificationService } from '../../../services/notification.service';

@Component({
  selector: 'app-support-modal',
  templateUrl: './support-modal.component.html',
  styleUrls: ['./support-modal.component.scss']
})
export class SupportModalComponent implements OnInit, OnDestroy {
  @ViewChild('supportForm', { static: true }) supportForm: NgForm;
  @Input() support?: any = {};

  supportSubscription: Subscription;

  constructor(private adminService: AdminService, public activeModal: NgbActiveModal, private notificationService: NotificationService) { }

  ngOnInit() {
  }

  ngOnDestroy() {
    if (this.supportSubscription instanceof Subscription) this.supportSubscription.unsubscribe();
  }

  sendRequest() {
    if (this.supportForm.invalid) return;

    this.supportSubscription = this.adminService.sendSupportRequest(this.support).subscribe((response: any) => {
      //console.log('[SUPPORT_REQUEST]', response);
      this.activeModal.close();
      this.notificationService.showToast('Request correctly sended', { type: 'success' });
    });
  }

}
