import { Component, Input, OnInit } from '@angular/core';

import { AuthenticationService } from '../../../services/authentication.service';

@Component({
  selector: 'password-requirements',
  templateUrl: './password-requirements.component.html',
  styleUrls: ['./password-requirements.component.scss']
})
export class PasswordRequirementsComponent implements OnInit {
  @Input() password: string;

  get lengthMatched() { return (this.password || '').match(this.authenticationService.passwordRequirements.lengthMatch); }
  get uppercaseLowercaseMatched() { return (this.password || '').match(this.authenticationService.passwordRequirements.lowercaseMatch) && this.password.match(this.authenticationService.passwordRequirements.uppercaseMatch); }
  get numberSymbolMatched() { return (this.password || '').match(this.authenticationService.passwordRequirements.numberOrSymbolMatch); }

  constructor(private authenticationService: AuthenticationService) { }

  ngOnInit() {
  }

}
