
import { Subject } from 'rxjs';

import * as RecordRTC from 'recordrtc';
import * as hark from 'hark';

export class Chatbot {
    sessionId: number;
    sessionKey: string;
    sessionLang: string;
    sessionUserData: any;
    sessionDebug: any = {};
    outputAudio: boolean = false;

    inputMessage: string = '';
    dialog: Array<any> = [];

    recorder: any;
    recorderState: 'recording' | 'stopped';
    blobInput: any;

    onRestoreSession: Subject<any> = new Subject<any>();
    onStopRecording: Subject<any> = new Subject<any>();

    constructor(chatbotId: number, chatbotKey: string, chatbotLang: string, sessionSettings?: any) {
        this.sessionId = chatbotId;
        this.sessionKey = chatbotKey;
        this.sessionLang = chatbotLang;
        this.sessionUserData = (sessionSettings || {}).sessionUserData || {};
        this.outputAudio = (sessionSettings || {}).outputAudio;
    }

    restoreChatbot(sessionSettings: any) {
        this.onRestoreSession.next(sessionSettings);
    }

    processOrchestrateResponse(orchestrateResponse: any, audioInput?: boolean) {
        this.inputMessage = '';
        if (audioInput) this.resolveAudio(this.blobInput, 'input', orchestrateResponse.results.aiResponse.result.resolvedQuery);
        this.updateDialog('bot', orchestrateResponse.results.aiResponse.result.fulfillment.messages);

        const interactionInfo = {
            userQuery: orchestrateResponse.results.aiResponse.result.resolvedQuery,
            parameters: orchestrateResponse.results.aiResponse.result.parameters,
            intent: orchestrateResponse.results.aiResponse.result.metadata.intentName,
            action: orchestrateResponse.results.aiResponse.result.action,
            contexts: this.processDebugContexts(orchestrateResponse.results.aiResponse.result.contexts || []),
            botResponse: this.processDebugMessages(orchestrateResponse.results.aiResponse.result.fulfillment.messages),
            confidence: orchestrateResponse.results.aiResponse.result.score,
            sentiment: (orchestrateResponse.results.naturalLanguageResponse || {}).sentiment
        }
        this.updateDebugInfo(orchestrateResponse, interactionInfo);

        if (orchestrateResponse.results.aiResponse.result.fulfillment.audioOutput) this.getAudioBlob(orchestrateResponse.results.aiResponse.result.fulfillment.audioOutput);
    }

    processFulfillmentResponse(fulfillmentResponse: any) {
        this.inputMessage = '';
        let debugMessages = [];
        fulfillmentResponse.queryResult.fulfillmentMessages.forEach(message => {
            const messages = [{
                text: (message.text || {}).text ? message.text.text[0] : null,
                payload: (message.payload || {}).graphics ? message.payload : null
            }];
            this.updateDialog('bot', messages);

            if (message.text && message.text.text) debugMessages.push({text: message.text.text[0]});
        });
        const interactionInfo = {
            userQuery: fulfillmentResponse.queryResult.queryText,
            parameters: fulfillmentResponse.queryResult.parameters,
            intent: fulfillmentResponse.queryResult.intent.displayName,
            action: null,
            contexts: this.processDebugContexts(fulfillmentResponse.queryResult.outputContexts || []),
            botResponse: this.processDebugMessages(debugMessages),
            confidence: fulfillmentResponse.queryResult.intentDetectionConfidence,
            sentiment: null
        }
        this.updateDebugInfo(fulfillmentResponse, interactionInfo);
    }

    updateDialog(source: 'bot' | 'user', messages: Array<any> | string) {
        if (source === 'user' && !Array.isArray(messages)) messages = [{ text: messages }];
        this.dialog.push({ type: source, messages });
    }

    updateDebugInfo(response: any, interactionDebug: any) {
        this.sessionDebug = {
            apiResponse: response,
            interaction: interactionDebug
        };
    }

    processDebugContexts(contexts: Array<any>): Array<string> {
        let contextsName: Array<string> = [];
        contexts.forEach((context: any) => {
            const splittedContext = context.name.split('/contexts/');
            contextsName.push(splittedContext[splittedContext.length - 1]);
        });
        return contextsName;
    }

    processDebugMessages(messages: Array<any>): Array<string> {
        let speechesMessage: Array<string> = [];
        messages.forEach((message: any) => {
            speechesMessage.push(message.text);
        });
        return speechesMessage;
    }

    startRecording() {
        window.navigator.mediaDevices.getUserMedia({ audio: true }).then(async (stream: any) => {
            this.recorder = new RecordRTC.RecordRTCPromisesHandler(stream, {
                type: 'audio',              // audio, video, canvas, gif
                //mimeType: 'audio/webm',   // audio/webm, audio/wav
                recorderType: RecordRTC.StereoAudioRecorder,
                //checkForInactiveTracks: true,
                numberOfAudioChannels: 1,
                disableLogs: true
            });

            await this.recorder.startRecording().then(() => this.recorderState = 'recording');

            let speechEvents = hark(stream, {});
            speechEvents.on('stopped_speaking', () => {
                if (!this.recorder) return;
                speechEvents.stop();
                this.stopRecording();
            });
        });
    }

    async stopRecording() {
        await this.recorder.stopRecording().then(() => this.recorderState = 'stopped');
        this.blobInput = await this.recorder.getBlob();
        let base64 = await this.getAudioBase64(this.blobInput);

        this.onStopRecording.next({ blobUrl: URL.createObjectURL(this.blobInput), audioBase64: base64 });
        this.recorder.destroy();
        this.recorder = null;
    }

    getAudioBase64(blob: any): Promise<string> {
        return new Promise<string>((resolve) => {
            let reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = () => {
                let audioBase64 = (reader.result as string).split(',')[1];
                resolve(audioBase64);
            };
        });
    }

    resolveAudio(blob: any, audioType: 'input' | 'output', resolvedQuery?: string) {
        const audioPayload: Array<any> = [{
            payload: {
              graphics: {
                elements: [{
                  type: 'audio',
                  items: [{ source: URL.createObjectURL(blob), query: resolvedQuery || '' }]
                }]
              }
            }
        }];
        if (audioType === 'input') {
            this.updateDialog('user', audioPayload);
            this.blobInput = null;
        } else if (audioType === 'output') {
            this.updateDialog('bot', audioPayload);
        }
    }

    getAudioBlob(base64: string) {
        const byteString = atob(base64);
        const arrayBuffer = new ArrayBuffer(byteString.length);
        let integersArray = new Uint8Array(arrayBuffer);

        for (var i = 0; i < byteString.length; i++) {
            integersArray[i] = byteString.charCodeAt(i);
        }

        this.resolveAudio(new Blob([arrayBuffer], { type: '' }), 'output');
    }

}
