import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgbModal, NgbNav } from '@ng-bootstrap/ng-bootstrap';
import { filter, first } from 'rxjs/operators';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { DesignService } from 'src/app/services/design.service';
import { LicenseService } from 'src/app/services/license.service';
import { CustomReportModalComponent } from '../modals/custom-report-modal/custom-report-modal.component';

@Component({
  selector: 'app-analytics',
  templateUrl: './analytics.component.html',
  styleUrls: ['./analytics.component.scss']
})
export class AnalyticsComponent implements OnInit, OnDestroy {

  @ViewChild('analyticsNav', { static: true }) analyticsNav: NgbNav;
  
  filter: any = {};
  customReports: any[] = [];
  subscriptions = {};
  bqReportNameSelected;

  constructor(private modalService: NgbModal, private analyticsService: AnalyticsService, private designService: DesignService, private licenseService: LicenseService) { }

  ngOnInit() {
    let today = new Date();
    this.filter = {
      period: {
        fromDate: {
          day: today.getDate(),
          month: today.getMonth() + 1,
          year: today.getFullYear() - 1
        },
        toDate: {
          day: today.getDate(),
          month: today.getMonth() + 1,
          year: today.getFullYear()
        },
      },
      intentTag: ''
    }
    this.subscriptions['JourneySubscription'] = this.designService.getSessionJourney().subscribe(data => {
      this.licenseService.getSessionLicense().pipe(first()).subscribe(() => {
          if (this.licenseService.isPurchased('M_A')) {
            this.refreshCustomReports();
          } else {
              this.customReports = [];
              if(['sessions', 'intents', 'proficiency', 'conversational_history'].indexOf(this.analyticsNav.activeId) < 0) {
                this.bqReportNameSelected = null;
                this.analyticsNav.select('sessions');
              }
          }
        }
      )
    });
  }

  ngOnDestroy() {
    Object.keys(this.subscriptions).forEach((key: string) => {
      this.subscriptions[key].unsubscribe();
    });
  }

  processChartData(chart: string, data: any): Array<Array<any>> {
    let chartData: Array<Array<any>> = [];
    if (!data) return chartData;
    switch (chart) {
      case 'ConversationsStatistics':
        chartData.push(['Date', 'Sessions']);
        data.forEach((date: any) => {
          chartData.push([new Date(date.currentDate), date.value]);
        });
        break;
      case 'PopularTopicsAnalytics':
        chartData.push(['Tags', 'Value']);
        data.forEach((tag: any) => {
          chartData.push([tag.name, tag.number]);
        });
        break;
      case 'TopIntent':
      case 'TopFallback':
        chartData.push(['Intent', 'Count']);
        data.forEach((intent: any) => {
          chartData.push([intent.name, intent.value]);
        });
        break;
    }
    return chartData;
  }

  openCustomReportModal() {
    const modalRef = this.modalService.open(CustomReportModalComponent, {
      size: 'lg'
    });
    modalRef.componentInstance.onSave.subscribe(newReport => {
        this.bqReportNameSelected = newReport.bqReportName;
        this.refreshCustomReports();
    })
  }

  deleteReport(customReport) {
    this.subscriptions['deleteReport'] =  this.analyticsService.deleteReport(customReport.id).subscribe(() => {
      this.bqReportNameSelected = null;
      this.refreshCustomReports();
    })
  }

  private refreshCustomReports() {
    this.subscriptions['getAllReports'] = this.analyticsService.getAllReport().subscribe((data: any[]) => {
      this.customReports = data;
      if (this.bqReportNameSelected && this.customReports.find(el => el.bqReportName === this.bqReportNameSelected)) {
        this.analyticsNav.select(this.bqReportNameSelected);
      } else {
        this.analyticsNav.select('sessions');
      }
    })
  }

}
