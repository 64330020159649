
import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpRequest, HttpHandler } from '@angular/common/http';

import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';
import { DesignService } from '../services/design.service';

const interceptConfigurator: Array<string> = [
    `${environment.API.orchestrator_configurator}/intents/synchronize`,
    `${environment.API.orchestrator_configurator}/intents`,
    `${environment.API.orchestrator_configurator}/intents/parameters`,
    `${environment.API.orchestrator_configurator}/intents/flow`
];

@Injectable()
export class JourneyInterceptor implements HttpInterceptor {

    constructor(private designService: DesignService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if ( (req.url.indexOf(environment.API.orchestrator_analysis) !== -1 || interceptConfigurator.indexOf(req.url) !== -1) && !req.params.has('journeyApiKey') ) {
            return next.handle(
                req.clone({
                    params: req.params.set('journeyApiKey', this.designService.sessionJourney.apiKey)
                })
            );
        }

        return next.handle(req);
    }

}
