import { Component, OnInit, OnDestroy, Input } from '@angular/core';

import { DashboardService } from '../../../services/dashboard.service';
import { DesignService } from '../../../services/design.service';

declare let google: any;

@Component({
  selector: 'analytics-card',
  templateUrl: './analytics-card.component.html',
  styleUrls: ['./analytics-card.component.scss']
})
export class AnalyticsCardComponent implements OnInit, OnDestroy {
  @Input() processChartData: any;

  subscriptions: Object = {};
  conversationsStatisticsTotalAnalytics: number;
  interactionsAverageSessionAnalytics: number;
  interactionsAverageDurationAnalytics: number;
  interactionsChannel: any;

  currentData = new Date();

  constructor(private dashboardservice: DashboardService, private designService: DesignService) { }

  ngOnInit() {
    google.charts.load('current', {'packages': ['corechart'] });

    this.subscriptions['JourneySubscription'] = this.designService.getSessionJourney().subscribe(() => {
      this.analyticsPeriod({ activeId: null, nextId: 'today' });
    });
  }

  ngOnDestroy() {
    Object.keys(this.subscriptions).forEach((key: string) => {
      this.subscriptions[key].unsubscribe();
    });
  }

  analyticsPeriod($event) {
    if (typeof $event.activeId === 'undefined') return; //WORKAROUND TO PREVENT DOUBLE TRIGGER ON VIEW INIT

    let params: any;
    var yesterdayMidnight = new Date(this.currentData.getFullYear(), this.currentData.getMonth(), this.currentData.getDate() -1, 0,0,0,0).getTime();
    var yesterdayMidday = new Date(this.currentData.getFullYear(), this.currentData.getMonth(), this.currentData.getDate() -1, 23,59,59,999).getTime();

    if ($event.nextId === 'today') {
      params = {
        startDate: this.currentData.setHours(0,0,0,0),
        endDate: this.currentData.setHours(23,59,59,999)
     };
    } else if ($event.nextId === 'yesterday') {
      params = {
        startDate: yesterdayMidnight,
        endDate:  yesterdayMidday
     };
    }
    
    this.getConversationsStatisticsTotalAnalytics(params);
    this.getInteractionsAverageSessionAnalytics(params);
    this.getInteractionsAverageDurationAnalytics(params);
    this.getAgentChannelsStats(params);
  }

  getConversationsStatisticsTotalAnalytics(params: any) {
    this.subscriptions['ConversationsStatisticsTotalAnalytics'] = 
    this.dashboardservice.getConversationsStatistics(params.startDate, params.endDate, params.tags)
    .subscribe((response: any) => {
      this.conversationsStatisticsTotalAnalytics = response.multiObject.total.value;
    })
  }

  getInteractionsAverageDurationAnalytics(params: any) {
    this.subscriptions['InteractionsAverageDurationAnalytics'] = 
    this.dashboardservice.getInteractionsAverageDuration(params.startDate, params.endDate, params.tags)
    .subscribe((response: any) => {
      this.interactionsAverageDurationAnalytics = response.singleResult.value;
    });
  }

  getInteractionsAverageSessionAnalytics(params: any) {
    this.subscriptions['InteractionsAverageSessionAnalytics'] = 
    this.dashboardservice.getInteractionsAverageSession(params.startDate, params.endDate, params.tags)
    .subscribe((response: any) => {
      this.interactionsAverageSessionAnalytics = response.singleResult.value;
    })
  }

  getAgentChannelsStats(params: any){
    this.subscriptions['InteractionsAgentChannel'] = this.dashboardservice.getAgentChannels(params.startDate, params.endDate).subscribe((response: any) => {
      this.interactionsChannel  = {
        website: response.singleResult.find( ({ name }) => name === 'WEBSITE' ),
        app: response.singleResult.find( ({ name }) => name === 'APP' ),
        undefined: response.singleResult.find( ({ name }) => name === 'UNDEFINED' )
      };
      this.drawAnalyticsChart();
    })
  }

  drawAnalyticsChart() {
    google.charts.setOnLoadCallback(() => {
      let data = new google.visualization.DataTable();
      data.addColumn('string', 'Channel');
      data.addColumn('number', 'Value');

      let options = {
        pieHole: 0.7,
        legend: 'none',
        pieSliceText: 'none',
        colors: []
      };

      if (this.isEmptyStats()) {
        data.addColumn({type: 'string', role: 'tooltip'});
        data.addRows([
          ['No data', 100, 'No data']
        ]);

        options.colors = ['#E3E3E3'];
      } else {
        data.addRows([
          ['Website', this.interactionsChannel.website.number,],
          ['App', this.interactionsChannel.app.number,],
          ['Undefined', this.interactionsChannel.undefined.number,]
        ]);

        options.colors = ['#647DFC', '#F5008C','#E8ECFF'];
      }

      let chart = new google.visualization.PieChart(document.getElementById('chart_agent'));

      chart.draw(data, options);
    });
  }

  isEmptyStats(): boolean {
    if (this.interactionsChannel.website.number > 0 || this.interactionsChannel.app.number > 0 || this.interactionsChannel.undefined.number > 0) {
      return false;
    } else {
      return true;
    }
  }

}
