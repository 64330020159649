import { Directive, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[restrictChar]'
})
export class RestrictCharDirective {
  @Input() restrictChar: 'context' | 'event' | 'intentParameter' | 'action';

  constructor() { }

  @HostListener('keydown', ['$event']) onKeyDown($event: KeyboardEvent) {
    if (!this.keyValidation($event)) $event.preventDefault();
  }

  keyValidation($event: KeyboardEvent): boolean {
    switch (this.restrictChar) {
      case 'context':
      case 'intentParameter':
      case 'event':
        return /[A-Za-z0-9_-]/.test($event.key);
      case 'action':
        return /\S/.test($event.key);
      default:
        return true;
    }
  }

}
