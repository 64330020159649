import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthenticationGuard } from './guards/authentication.guard';
import { RoleRestrictionGuard } from './guards/role-restriction.guard';
import { LicenseRestrictionGuard } from './guards/license-restriction.guard';

import { MainComponent } from './components/main/main.component';
import { LoginComponent } from './components/login/login.component';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { DesignComponent } from './components/design/design.component';
import { TrainingComponent } from './components/training/training.component';
import { AnalyticsComponent } from './components/analytics/analytics.component';
import { AdminComponent } from './components/admin/admin.component';
import { IntentSettingsComponent } from './components/intent-settings/intent-settings.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { BillingComponent } from './components/billing/billing.component';
import { MarketplaceComponent } from './components/marketplace/marketplace.component';
import { ConversationFlowComponent } from './components/design/conversation-flow/conversation-flow.component';

//const roles = ['ADMIN', 'ANALYST', 'BILLING', 'CONVERSATIONAL_DESIGNER', 'EXPERIENCE_DESIGNER', 'MONITORING', 'TRAINER'];

const routes: Routes = [
  { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
  { path: '',
    component: MainComponent,
    canActivateChild: [AuthenticationGuard],
    children: [
      {
        path: 'dashboard',
        component: DashboardComponent
      },
      {
        path: 'design',
        component: DesignComponent,
        canActivate: [RoleRestrictionGuard],
        data: {
          roleRestriction: ['ADMIN', 'ANALYST', 'TRAINER', 'EXPERIENCE_DESIGNER', 'CONVERSATIONAL_DESIGNER']
        }
      },
      {
        path: 'conversation-flow',
        component: ConversationFlowComponent,
        canActivate: [LicenseRestrictionGuard, RoleRestrictionGuard],
        data: {
          licenseRestriction: 'M_I',
          roleRestriction: ['ADMIN', 'ANALYST', 'TRAINER', 'EXPERIENCE_DESIGNER', 'CONVERSATIONAL_DESIGNER']
        }
      },
      {
        path: 'intent-settings',
        component: IntentSettingsComponent,
        canActivate: [RoleRestrictionGuard],
        data: {
          roleRestriction: ['ADMIN', 'ANALYST', 'TRAINER', 'EXPERIENCE_DESIGNER', 'CONVERSATIONAL_DESIGNER']
        }
      },
      {
        path: 'marketplace',
        component: MarketplaceComponent,
        data: {
          roleRestriction: ['ADMIN', 'TRAINER', 'ANALYST', 'CONVERSATIONAL_DESIGNER']
        }
      },
      {
        path: 'training',
        component: TrainingComponent,
        canActivate: [RoleRestrictionGuard],
        data: {
          roleRestriction: ['ADMIN', 'TRAINER']
        }
      },
      {
        path: 'analytics',
        component: AnalyticsComponent,
        canActivate: [RoleRestrictionGuard],
        data: {
          roleRestriction: ['ADMIN', 'ANALYST', 'EXPERIENCE_DESIGNER', 'CONVERSATIONAL_DESIGNER']
        }
      },
      {
        path: 'admin',
        component: AdminComponent,
        canActivate: [RoleRestrictionGuard],
        data: {
          roleRestriction: ['ADMIN']
        }
      },
      {
        path: 'billing',
        component: BillingComponent
      }
    ]
  },
  {
    path: 'change-password',
    component: ChangePasswordComponent,
    canActivate: [AuthenticationGuard]
  },
  { path: 'login', component: LoginComponent },
  { path: 'reset-password', component: ResetPasswordComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
