import { Component, OnInit, ViewEncapsulation, OnDestroy, ViewChildren, QueryList } from '@angular/core';

import { Subscription } from 'rxjs';

import { Pagination } from '../../../classes/pagination';
import { AdminService } from '../../../services/admin.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { TableSortDirective } from 'src/app/directives/table-sort.directive';

@Component({
  selector: 'audit-log',
  templateUrl: './audit-log.component.html',
  styleUrls: ['./audit-log.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AuditLogComponent implements OnInit, OnDestroy {
  auditLogSubscription: Subscription;
  auditTypesSubscription: Subscription;
  accountSubscription: Subscription;
  componentTypesSubscription: Subscription;

  auditLogPagination: Pagination = new Pagination();

  filter: any = {
    eventType: '',
    component: '',
    sortBy: 'eventDate:desc'
  };

  auditLog: Array<any> = [];
  auditTypes: Array<string> = [];
  componentsTypes: Array<string> = [];
  @ViewChildren(TableSortDirective) thead: QueryList<TableSortDirective>;
  
  constructor(private adminService: AdminService, private authenticationService: AuthenticationService) { }

  ngOnInit() {
    this.getComponentsTypes();
    this.auditTypesSubscription = this.adminService.getAuditTypes().subscribe((response: any) => {
      //console.log('[AUDIT_TYPES]', response);
      this.auditTypes = response;
    });
    this.searchAuditLog(1);

    this.accountSubscription = this.authenticationService.onChangeSessionAccount().subscribe(() => {
      this.searchAuditLog(1);
    });
  }

  ngOnDestroy() {
    this.accountSubscription.unsubscribe();
    this.auditTypesSubscription.unsubscribe();
    this.auditLogSubscription.unsubscribe();
    this.componentTypesSubscription.unsubscribe();
  }

  updateDate($event) {
    //console.log('[EVENT]', $event);
    if ($event.type === 'from') this.filter.fromDate = $event.date;
    if ($event.type === 'to') this.filter.toDate = $event.date;
  }

  getComponentsTypes() {
    this.componentTypesSubscription = this.adminService.getComponentsTypes().subscribe((response: any) =>{
      this.componentsTypes = response;
    })
  }

  searchAuditLog(pageSelected: number) {
    //console.log('[FILTERS]', this.filter);
    this.auditLogPagination.onSelectPage(pageSelected);

    const params = {
      component: this.filter.component || null,
      eventType: this.filter.eventType || null,
      principal: this.filter.principal || null,
      startDate: this.filter.fromDate ? new Date(this.filter.fromDate.year, this.filter.fromDate.month - 1, this.filter.fromDate.day, 0, 0, 0).getTime() : null,
      endDate: this.filter.toDate ? new Date(this.filter.toDate.year, this.filter.toDate.month - 1, this.filter.toDate.day, 23, 59, 59).getTime() : null,
      sortBy: this.filter.sortBy || null
    };

    this.auditLogSubscription = this.adminService.getAuditLog(params, this.auditLogPagination.getPageIndex(), this.auditLogPagination.pageSize).subscribe((response: any) => {
      //console.log('[AUDIT_LOG]', response);
      this.auditLog = response.content;
      this.auditLogPagination.updateTotals(response.totalElements);
    });
  }

  resetFilters() {
    this.filter = {
      eventType: '',
      component: ''
    };
  }

  onSort({column, direction}: any) {
    this.thead.forEach((th: any) => {
      if (th.sortable !== column) {
        th.direction = '';
      }
    });

    this.filter.sortBy = (direction) ? `${column}:${direction}` : null;
    this.searchAuditLog(this.auditLogPagination.currentPage);
  }

}
