import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LOCALE_ID } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localeIt from '@angular/common/locales/it';

import { NgbModule, NgbDateAdapter, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { CookieService } from 'ngx-cookie-service';
import { CarouselModule } from 'ngx-owl-carousel-o';

import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DragDropModule } from '@angular/cdk/drag-drop';

import { DatepickerAdapter } from './classes/datepicker-adapter';
import { DateParserFormatter } from './classes/date-parser-formatter';

import { FullTextSearchPipe } from './pipes/full-text-search.pipe';

import { httpInterceptorProviders } from './http-interceptors';

import { Ng5SliderModule } from 'ng5-slider';
import { AngularResizedEventModule } from 'angular-resize-event';
import { NgxGraphModule } from '@swimlane/ngx-graph';

import { AppComponent } from './app.component';
import { MainComponent } from './components/main/main.component';
import { ConsoleHeaderComponent } from './components/console-header/console-header.component';
import { ConsoleMenuComponent } from './components/console-menu/console-menu.component';
import { NotificationModalComponent } from './components/modals/notification-modal/notification-modal.component';
import { RatingModalComponent } from './components/modals/rating-modal/rating-modal.component';
import { ToastNotificationComponent } from './components/utility/toast-notification/toast-notification.component';
import { DatepickerRangeComponent } from './components/utility/datepicker-range/datepicker-range.component';
import { LoginComponent } from './components/login/login.component';
import { TrainingComponent } from './components/training/training.component';
import { TrainingModalComponent } from './components/modals/training-modal/training-modal.component';
import { AdminComponent } from './components/admin/admin.component';
import { UserModalComponent } from './components/modals/user-modal/user-modal.component';
import { AuditLogComponent } from './components/admin/audit-log/audit-log.component';
import { SpinnerLoaderDirective } from './directives/spinner-loader.directive';
import { SpinnerLoaderComponent } from './components/utility/spinner-loader/spinner-loader.component';
import { AnalyticsComponent } from './components/analytics/analytics.component';
import { AnalyticsFilterComponent } from './components/analytics/analytics-filter/analytics-filter.component';
import { AnalyticSessionsComponent } from './components/analytics/analytic-sessions/analytic-sessions.component';
import { ConversationalHistoryComponent } from './components/analytics/conversational-history/conversational-history.component';
import { AnalyticIntentsComponent } from './components/analytics/analytic-intents/analytic-intents.component';
import { AnalyticProficiencyComponent } from './components/analytics/analytic-proficiency/analytic-proficiency.component';
import { DesignComponent } from './components/design/design.component';
import { ChatComponent } from './components/chat/chat.component';
import { ChatDebugModalComponent } from './components/modals/chat-debug-modal/chat-debug-modal.component';
import { EntitySelectionDirective } from './directives/entity-selection.directive';
import { IntentsReliabilityComponent } from './components/intents-reliability/intents-reliability.component';
import { StarRatingComponent } from './components/utility/star-rating/star-rating.component';
import { AccessManagementComponent } from './components/admin/access-management/access-management.component';
import { TableSortDirective } from './directives/table-sort.directive';
import { IntentParametersComponent } from './components/intent-parameters/intent-parameters.component';
import { IntentTagsComponent } from './components/intent-tags/intent-tags.component';
import { IntentSettingsComponent } from './components/intent-settings/intent-settings.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { RoleRestrictionDirective } from './directives/role-restriction.directive';
import { SmtpConnectorModalComponent } from './components/design/connectors/smtp-connector-modal/smtp-connector-modal.component';
import { StepsCompletionComponent } from './components/utility/steps-completion/steps-completion.component';
import { JourneyModalComponent } from './components/modals/journey-modal/journey-modal.component';
import { HttpConnectorModalComponent } from './components/design/connectors/http-connector-modal/http-connector-modal.component';
import { NluConnectorModalComponent } from './components/design/connectors/nlu-connector-modal/nlu-connector-modal.component';
import { GenericRuleModalComponent } from './components/design/connectors/generic-rule-modal/generic-rule-modal.component';
import { SupportModalComponent } from './components/modals/support-modal/support-modal.component';
import { DialogflowConnectorModalComponent } from './components/design/connectors/dialogflow-connector-modal/dialogflow-connector-modal.component';
import { ChatSettingsModalComponent } from './components/modals/chat-settings-modal/chat-settings-modal.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { TrainingTeamComponent } from './components/admin/training-team/training-team.component';
import { TopicsModalComponent } from './components/modals/topics-modal/topics-modal.component';
import { OverviewCardComponent } from './components/dashboard/overview-card/overview-card.component';
import { AnalyticsCardComponent } from './components/dashboard/analytics-card/analytics-card.component';
import { TrainingCardComponent } from './components/dashboard/training-card/training-card.component';
import { EventsCardComponent } from './components/dashboard/events-card/events-card.component';
import { StatusCardComponent } from './components/dashboard/status-card/status-card.component';
import { BillingComponent } from './components/billing/billing.component';
import { RequestsCardComponent } from './components/billing/requests-card/requests-card.component';
import { ConsumptionCardComponent } from './components/billing/consumption-card/consumption-card.component';
import { DlpConnectorModalComponent } from './components/design/connectors/dlp-connector-modal/dlp-connector-modal.component';
import { TranslateConnectorModalComponent } from './components/design/connectors/translate-connector-modal/translate-connector-modal.component';
import { UserSettingsModalComponent } from './components/modals/user-settings-modal/user-settings-modal.component';
import { SettingAlertsComponent } from './components/modals/user-settings-modal/setting-alerts/setting-alerts.component';
import { SettingProfileComponent } from './components/modals/user-settings-modal/setting-profile/setting-profile.component';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { SettingLicenseComponent } from './components/modals/user-settings-modal/setting-license/setting-license.component';
import { TrooveConnectorModalComponent } from './components/design/connectors/troove-connector-modal/troove-connector-modal.component';
import { ButtonItemComponent } from './components/intent-parameters/response-items/button-item/button-item.component';
import { ListItemComponent } from './components/intent-parameters/response-items/list-item/list-item.component';
import { CarouselCardItemComponent } from './components/intent-parameters/response-items/carousel-card-item/carousel-card-item.component';
import { ChipsItemComponent } from './components/intent-parameters/response-items/chips-item/chips-item.component';
import { ParametersResponseFormComponent } from './components/intent-parameters/parameters-response-form/parameters-response-form.component';
import { PaginationComponent } from './components/utility/pagination/pagination.component';
import { DataTransferConnectorModalComponent } from './components/design/connectors/data-transfer-connector-modal/data-transfer-connector-modal.component';
import { MarketplaceComponent } from './components/marketplace/marketplace.component';
import { MarketConnectorsComponent } from './components/marketplace/market-connectors/market-connectors.component';
import { MarketTemplatesComponent } from './components/marketplace/market-templates/market-templates.component';
import { MarketModulesComponent } from './components/marketplace/market-modules/market-modules.component';
import { PasswordRequirementsComponent } from './components/utility/password-requirements/password-requirements.component';
import { DeleteDialogflowConnectorModalComponent } from './components/design/connectors/delete-dialogflow-connector-modal/delete-dialogflow-connector-modal.component';
import { ConversationFlowComponent } from './components/design/conversation-flow/conversation-flow.component';
import { UrlSanitizerPipe } from './pipes/url-sanitizer.pipe';
import { DataProcessingConnectorModalComponent } from './components/design/connectors/data-processing-connector-modal/data-processing-connector-modal.component';
import { AnalyticReportsComponent } from './components/analytics/analytic-reports/analytic-reports.component';
import { CustomReportModalComponent } from './components/modals/custom-report-modal/custom-report-modal.component';
import { ReportChartModalComponent } from './components/modals/report-chart-modal/report-chart-modal.component';
import { CustomReportDetailsModalComponent } from './components/modals/custom-report-details-modal/custom-report-details-modal.component';
import { LicenseRestrictionDirective } from './directives/license-restriction.directive';
import { IntentModalComponent } from './components/modals/intent-modal/intent-modal.component';
import { InputContextFieldComponent } from './components/design/input-context-field/input-context-field.component';
import { OutputContextFieldComponent } from './components/design/output-context-field/output-context-field.component';
import { RestrictCharDirective } from './directives/restrict-char.directive';
import { ZendeskConnectorModalComponent } from './components/design/connectors/zendesk-connector-modal/zendesk-connector-modal.component';

registerLocaleData(localeIt, 'it');

@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    ToastNotificationComponent,
    DatepickerRangeComponent,
    SpinnerLoaderDirective,
    FullTextSearchPipe,
    ConsoleHeaderComponent,
    ConsoleMenuComponent,
    SpinnerLoaderComponent,
    LoginComponent,
    NotificationModalComponent,
    TrainingComponent,
    TrainingModalComponent,
    AdminComponent,
    UserModalComponent,
    AuditLogComponent,
    AnalyticsComponent,
    AnalyticsFilterComponent,
    AnalyticSessionsComponent,
    ConversationalHistoryComponent,
    AnalyticIntentsComponent,
    AnalyticProficiencyComponent,
    DesignComponent,
    RatingModalComponent,
    ChatComponent,
    ChatDebugModalComponent,
    EntitySelectionDirective,
    IntentsReliabilityComponent,
    StarRatingComponent,
    AccessManagementComponent,
    TableSortDirective,
    IntentParametersComponent,
    IntentTagsComponent,
    IntentSettingsComponent,
    ResetPasswordComponent,
    RoleRestrictionDirective,
    SmtpConnectorModalComponent,
    StepsCompletionComponent,
    JourneyModalComponent,
    HttpConnectorModalComponent,
    NluConnectorModalComponent,
    GenericRuleModalComponent,
    SupportModalComponent,
    DialogflowConnectorModalComponent,
    ChatSettingsModalComponent,
    DashboardComponent,
    TrainingTeamComponent,
    TopicsModalComponent,
    OverviewCardComponent,
    AnalyticsCardComponent,
    TrainingCardComponent,
    EventsCardComponent,
    StatusCardComponent,
    BillingComponent,
    RequestsCardComponent,
    ConsumptionCardComponent,
    DlpConnectorModalComponent,
    TranslateConnectorModalComponent,
    UserSettingsModalComponent,
    SettingAlertsComponent,
    SettingProfileComponent,
    ChangePasswordComponent,
    SettingLicenseComponent,
    TrooveConnectorModalComponent,
    ButtonItemComponent,
    ListItemComponent,
    CarouselCardItemComponent,
    ChipsItemComponent,
    ParametersResponseFormComponent,
    PaginationComponent,
    DataTransferConnectorModalComponent,
    MarketplaceComponent,
    MarketConnectorsComponent,
    MarketTemplatesComponent,
    MarketModulesComponent,
    PasswordRequirementsComponent,
    DeleteDialogflowConnectorModalComponent,
    ConversationFlowComponent,
    UrlSanitizerPipe,
    DataProcessingConnectorModalComponent,
    AnalyticReportsComponent,
    CustomReportModalComponent,
    ReportChartModalComponent,
    CustomReportDetailsModalComponent,
    LicenseRestrictionDirective,
    IntentModalComponent,
    InputContextFieldComponent,
    OutputContextFieldComponent,
    RestrictCharDirective,
    ZendeskConnectorModalComponent
  ],
  imports: [
    Ng5SliderModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    NgbModule,
    CarouselModule,
    AngularResizedEventModule,
    NgxGraphModule,
    DragDropModule
  ],
  providers: [
    httpInterceptorProviders,
    CookieService,
    { provide: LOCALE_ID, useValue: 'it' },
    { provide: NgbDateAdapter, useClass: DatepickerAdapter },
    { provide: NgbDateParserFormatter, useClass: DateParserFormatter }
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    NotificationModalComponent,
    SpinnerLoaderComponent,
    TrainingModalComponent,
    UserModalComponent,
    RatingModalComponent,
    ChatDebugModalComponent,
    SmtpConnectorModalComponent,
    JourneyModalComponent,
    HttpConnectorModalComponent,
    NluConnectorModalComponent,
    GenericRuleModalComponent,
    SupportModalComponent,
    TopicsModalComponent,
    DialogflowConnectorModalComponent,
    ChatSettingsModalComponent,
    DlpConnectorModalComponent,
    TranslateConnectorModalComponent,
    UserSettingsModalComponent,
    TrooveConnectorModalComponent,
    DataTransferConnectorModalComponent,
    DeleteDialogflowConnectorModalComponent,
    DataProcessingConnectorModalComponent,
    CustomReportModalComponent,
    ReportChartModalComponent,
    CustomReportDetailsModalComponent,
    IntentModalComponent,
    ZendeskConnectorModalComponent
  ]
})
export class AppModule { }
