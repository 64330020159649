import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { Subscription } from 'rxjs';

import { IntentService } from '../../../services/intent.service';

@Component({
  selector: 'analytics-filter',
  templateUrl: './analytics-filter.component.html',
  styleUrls: ['./analytics-filter.component.scss']
})
export class AnalyticsFilterComponent implements OnInit {
  @Input() filter?: any = {};
  @Output() getStatistics = new EventEmitter<any>();

  subscriptions: Array<Subscription> = [];
  tags: Array<any> = [];

  constructor(private intentService: IntentService) { }

  ngOnInit() {
    this.getTags();
  }

  ngOnDestroy() {
    Object.keys(this.subscriptions).forEach((key: string) => {
      this.subscriptions[key].unsubscribe();
    });
  }

  getTags() {
    this.subscriptions['Tags'] = this.intentService.getTags({isLogic: false}).subscribe((response: any) => {
      this.tags = response;
    });
  }

  updateDate($event) {
    if ($event.type === 'from') this.filter.period.fromDate = $event.date;
    if ($event.type === 'to') this.filter.period.toDate = $event.date;
  }

  getStats() {
    this.getStatistics.emit();
  }
}
