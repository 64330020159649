import { Component, Input, OnInit, Output, EventEmitter, ViewEncapsulation, OnDestroy } from '@angular/core';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { AuthenticationService } from '../../services/authentication.service';
import { SupportModalComponent } from '../../components/modals/support-modal/support-modal.component';
import { UserSettingsModalComponent } from '../../components/modals/user-settings-modal/user-settings-modal.component';
import { DesignService } from '../../services/design.service';
import { CommonService } from '../../services/common.service';

@Component({
  selector: 'console-header',
  templateUrl: './console-header.component.html',
  styleUrls: ['./console-header.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ConsoleHeaderComponent implements OnInit, OnDestroy {
  @Output() switchChat = new EventEmitter<any>();
  @Input() openChat: boolean;
  
  subscriptions: Object = {};
  
  stretchMenu: boolean = false;

  userAccounts: Array<any>;
  journeys: Array<any> = [];
  activeJourney: any;

  get user() { return this.authenticationService.user }

  constructor(private authenticationService: AuthenticationService, private modalService: NgbModal, private designService: DesignService, private commonService: CommonService) { }

  ngOnInit() {
    this.getJourneys();
    this.getAccounts();

    this.subscriptions['ToggleMenu'] = this.commonService.onStretchMenu().subscribe((stretchStatus: boolean) => {
      this.stretchMenu = stretchStatus;
    });

    this.subscriptions['JourneysUpdate'] = this.designService.updateJourneys().subscribe(() => {
      this.getJourneys();
    });
  }

  ngOnDestroy() {
    Object.keys(this.subscriptions).forEach((key: string) => {
      this.subscriptions[key].unsubscribe();
    });
  }

  getJourneys() {
    this.subscriptions['Journeys'] = this.designService.getJourneysList().subscribe((response: any) => {
      this.journeys = response;
      //this.activeJourney = JSON.parse(sessionStorage.getItem('journey')) || response[0].journey;
      if (this.journeys && this.journeys.length > 0) {
        this.activeJourney = this.journeys[0].journey;
        
        this.selectJourney();
      }
    });
  }

  getAccounts() {
    this.subscriptions['Accounts'] = this.authenticationService.getAccountsUser().subscribe((response: any) => {
      this.userAccounts = response;
    });
  }

  switchAccount(account: any) {
    if (account.id === this.authenticationService.user.accountId) return;
    
    const data = {
      id: account.id
    };
    this.subscriptions['SetAccount'] = this.authenticationService.changeUserAccount(data).subscribe((response: any) => {
      this.getJourneys();
    });
  }

  compareSessionJourney(a, b): boolean {
    return a && b ? a.id === b.id : a === b;
  }

  selectJourney() {
    //sessionStorage.setItem('journey', JSON.stringify(this.activeJourney));
    this.designService.setSessionJourney(this.activeJourney);
  }

  toggleMenu(stretchStatus: boolean) {
    this.commonService.switchMenu(!stretchStatus);
  }

  toggleChat(openStatus: boolean) {
    this.openChat = !openStatus;
    this.switchChat.emit(this.openChat);
  }

  logout() {
    this.subscriptions['Logout'] = this.authenticationService.logout({}).subscribe();
  }

  modalSupport() {
    this.modalService.open(SupportModalComponent, {
      size: 'lg'
    });
  }

  modalSettings() {
    this.modalService.open(UserSettingsModalComponent, {
      size: 'xl'
    });
  }

}
