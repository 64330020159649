import { Component, Input, OnInit, Output, ViewEncapsulation, EventEmitter, OnDestroy } from '@angular/core';
import { AbstractControl, FormArray, FormGroup } from '@angular/forms';

import { OwlOptions } from 'ngx-owl-carousel-o';
import { Subscription } from 'rxjs';

@Component({
  selector: 'carousel-card-item',
  templateUrl: './carousel-card-item.component.html',
  styleUrls: ['./carousel-card-item.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CarouselCardItemComponent implements OnInit, OnDestroy {
  @Input() element: FormGroup;
  @Input() formSubmitted: boolean;
  @Output() onAddCard = new EventEmitter<any>();

  iterableChanges: Subscription;

  cardCarouselOptions: OwlOptions = {
    items: 3,
    //dots: false,
    margin: 10,
    autoWidth: true
  };

  get itemArray() { return this.element.get('items') as FormArray }

  constructor() { }

  ngOnInit() {
    this.iterableChanges = this.element.get('iterable').valueChanges.subscribe((value: boolean) => {
      if (value) {
        this.itemArray.controls.forEach((control: AbstractControl, index: number) => {
          if (index > 0) this.removeCard(index);
        })
      }
    });
  }

  ngOnDestroy() {
    this.iterableChanges.unsubscribe();
  }

  addCard() {
    this.onAddCard.emit();
  }

  removeCard(cardIndex: number) {
    if (this.itemArray.length === 1) return;
    this.itemArray.removeAt(cardIndex);
  }

}
