import { Component, OnInit } from '@angular/core';

import { LicenseService } from '../../../../services/license.service';

@Component({
  selector: 'setting-license',
  templateUrl: './setting-license.component.html',
  styleUrls: ['./setting-license.component.scss']
})
export class SettingLicenseComponent implements OnInit {
  license: any = {};
  
  currentDate = new Date().setHours(23,59,59,999);
  remainingPeriods: any = {};

  constructor(private licenseService: LicenseService) { }

  ngOnInit() {
    this.getProductsLicense();
  }

  getProductsLicense() {
    this.licenseService.getLicense().subscribe((response: any) => {
      this.license = response;
      this.processRemaining();
    });
  }

  processRemaining() {
    this.remainingPeriods.leftDays = Math.round((this.license.endDate - this.currentDate) / (24 * 60 * 60 * 1000));
    const totalDays = Math.round((this.license.endDate - this.license.startDate) / (24 * 60 * 60 * 1000));
    this.remainingPeriods.leftDaysPercentage = (this.remainingPeriods.leftDays > 0) ? Math.round((this.remainingPeriods.leftDays * 100) / totalDays) : 0;
  }

  remainingType(): 'success' | 'warning' | 'danger' {
    if (this.remainingPeriods.leftDaysPercentage <= 33) return 'danger';
    if (this.remainingPeriods.leftDaysPercentage > 33 && this.remainingPeriods.leftDaysPercentage <= 66) return 'warning';
    return 'success';
  }

}
