import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';

import { AuthenticationService } from '../services/authentication.service';

@Directive({
  selector: '[roleRestriction]'
})
export class RoleRestrictionDirective implements OnInit {
  @Input() roleRestriction: Array<string> | string;

  constructor(private templateRef: TemplateRef<any>, private viewContainer: ViewContainerRef, private authenticationService: AuthenticationService) { }

  ngOnInit() {
    if (!this.authenticationService.isAuthorized(this.roleRestriction)) {
      this.viewContainer.clear();
      return;
    } else if (this.authenticationService.isAuthorized(this.roleRestriction)) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }

}
