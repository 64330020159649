
export enum TagIcons {
    verified_user = 'verified_user',
    call_split = 'call_split',
    account_circle = 'account_circle',
    build = 'build',
    favorite = 'favorite',
    check_circle = 'check_circle',
    cancel = 'cancel',
    info = 'info',
    stars = 'stars',
    visibility = 'visibility',
    chat_bubble_outline = 'chat_bubble_outline',
    email = 'email',
    folder = 'folder',
    cloud_queue = 'cloud_queue',
    brightness_low = 'brightness_low',
    headset_mic = 'headset_mic',
    color_lens = 'color_lens',
    timer = 'timer',
    laptop_mac = 'laptop_mac',
    wifi = 'wifi',
    language = 'language',
    share = 'share',
    sentiment_satisfied_alt = 'sentiment_satisfied_alt',
    sentiment_very_dissatisfied = 'sentiment_very_dissatisfied',
    notifications_active = 'notifications_active',
    bar_chart = 'bar_chart'
}
