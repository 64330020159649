import { Injectable } from '@angular/core';
import { CanActivateChild, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';

//import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';

import { AuthenticationService } from '../services/authentication.service';
import { NotificationService } from '../services/notification.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationGuard implements CanActivateChild, CanActivate {

  constructor(private authenticationService: AuthenticationService, private router: Router, private notificationService: NotificationService) { }

  canActivateChild(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.authenticationVerify(state);
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.authenticationVerify(state);
  }

  private authenticationVerify(state: RouterStateSnapshot): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      if (!this.authenticationService.isLogged()) {        
        this.notificationService.showModalSpinner();
        this.authenticationService.getCurrentUser().subscribe((response: any) => {
          this.notificationService.hideModalSpinner();

          if (!response.verified && state.url !== '/change-password') {
            this.router.navigate(['change-password']);
            this.notificationService.showToast('You must change your Password before Sign-In', { type: 'warning' });
            reject(false);
          } else {
            resolve(true);
          }
        }, () => {
          this.authenticationService.eraseSession();
          this.notificationService.hideModalSpinner();
          reject(false);
        });
      } else {
        if (!this.authenticationService.user.verified && state.url !== '/change-password') {
          this.router.navigate(['change-password']);
          this.notificationService.showToast('You must change your Password before Sign-In', { type: 'warning' });
          reject(false);
        } else {
          resolve(true);
        }
      }
    });
  }
  
}
