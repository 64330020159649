import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ReportChart } from 'src/app/classes/report-chart';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { NotificationService } from 'src/app/services/notification.service';
import { CustomReportDetailsModalComponent } from '../../modals/custom-report-details-modal/custom-report-details-modal.component';
import { ReportChartModalComponent } from '../../modals/report-chart-modal/report-chart-modal.component';

declare let google: any;
@Component({
  selector: 'analytic-reports',
  templateUrl: './analytic-reports.component.html',
  styleUrls: ['./analytic-reports.component.scss']
})
export class AnalyticReportsComponent implements OnInit {

  @Input() report: any;
  @Output() delete = new EventEmitter<any>();

  filter: any = {};
  charts: ReportChart[] = [];
  tableChart: ReportChart;
  subscriptions = {};
  reportView: 'chart' | 'table' = 'chart';

  constructor(private modalService: NgbModal, private analyticsService: AnalyticsService, private notificationService: NotificationService) { }

  ngOnInit() {
    google.charts.load('current', { 'packages': ['corechart'] });
    let today = new Date();
    let aMonthAgo = new Date(today.getFullYear(), today.getMonth() - 1, today.getDate());
    this.filter = {
      fromDate: {
        day: aMonthAgo.getDate(),
        month: aMonthAgo.getMonth() + 1,
        year: aMonthAgo.getFullYear()
      },
      toDate: {
        day: today.getDate(),
        month: today.getMonth() + 1,
        year: today.getFullYear()
      },
    }
    this.refreshCharts();
  }

  addChart() {
    const modalRef = this.modalService.open(ReportChartModalComponent, {
      size: 'lg'
    });
    modalRef.componentInstance.fields = this.report.fields;
    modalRef.componentInstance.reportID = this.report.id;
    modalRef.componentInstance.reportView = this.reportView;
    modalRef.componentInstance.onSave.subscribe(() => {
      this.refreshCharts();
    })
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.charts, event.previousIndex, event.currentIndex);
  }

  updateDate($event) {
    if ($event.type === 'from') this.filter.fromDate = $event.date;
    if ($event.type === 'to') this.filter.toDate = $event.date;
  }

  deleteReport() {
    this.notificationService.openModal({
      title: 'Delete report',
      message: 'Deleting the report you will lost all your charts and table configurations and all the report data saved. Do you want to continue?',
      choice: 'multi'
    }).subscribe((confirm: boolean) => {
      if (!confirm) return;
      this.delete.emit();
    });
  }

  showDetails() {
    const modalRef = this.modalService.open(CustomReportDetailsModalComponent, {
      size: 'md'
    })
    modalRef.componentInstance.reportId = this.report.bqReportName;
  }

  deleteChart(chartId) {
    this.subscriptions['deleteChart'] =  this.analyticsService.deleteChart(chartId).subscribe(() => {
      this.refreshCharts();
    })
  }

  modifyChart(chart: ReportChart) {
    const modalRef = this.modalService.open(ReportChartModalComponent, {
      size: 'lg'
    });
    modalRef.componentInstance.chart = {...chart.options};
    modalRef.componentInstance.fields = this.report.fields;
    modalRef.componentInstance.reportID = this.report.id;
    modalRef.componentInstance.reportView = this.reportView;
    modalRef.componentInstance.onSave.subscribe(chart => {
      this.refreshCharts();
    })
  }

  switchView() {
    this.charts = [];
    this.tableChart = null;
    this.refreshCharts();
  }

  refreshCharts() {
    const params = {
      startDate: this.filter.fromDate ? new Date(this.filter.fromDate.year, this.filter.fromDate.month-1, this.filter.fromDate.day, 0, 0, 0).getTime() : null,
      endDate: this.filter.toDate ? new Date(this.filter.toDate.year, this.filter.toDate.month-1, this.filter.toDate.day, 23, 59, 59).getTime() : null,
      onlyTable: this.reportView === 'table'
    }
    if (this.subscriptions['getQueryResult']) {
      this.subscriptions['getQueryResult'].unsubscribe();
    }
    this.subscriptions['getQueryResult'] = this.analyticsService.getQueryResult(this.report.userReportName, params).subscribe((data: any[]) => {
      if (this.reportView === 'chart') {
        this.charts = data.map(el => new ReportChart(el.chart, el.data));
        setTimeout(() => {
          this.charts.forEach((chart, index) => {
             chart.drawChart(`custom_chart_${index}`)
          })
       })
      } else {
        this.tableChart = data.length > 0 ? new ReportChart(data[0].chart, data[0].data) : null;
        if (this.tableChart) this.tableChart.drawChart('');
      }
   })
  }
}
