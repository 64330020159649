import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MonitoringService {

  constructor(private http: HttpClient) { }

  getRequestStats(connectorName: string, days: number, startDate: number, endDate: number ) {
    const httpOptions = {
      params: new HttpParams()
        .set('days', days.toString())
        .set('startDate', startDate.toString())
        .set('endDate', endDate.toString())
    };
    return this.http.get(`${environment.API.orchestrator_analysis}/billing/${connectorName}`, httpOptions);
  }

  getBillingUsers(): Observable<any> {
    return this.http.get(`${environment.API.orchestrator_configurator}/planning/billing`);
  }

  getNotifiedUser() {
    return this.http.get(`${environment.API.orchestrator_configurator}/planning/notifiedUser`);
  }

  updateNotifiedUser(data: any, params: any): Observable<any> {
    const httpOptions = {
      params: new HttpParams()
        .set('enabled', params.enabled.toString())
    };

    return this.http.put(`${environment.API.orchestrator_configurator}/planning/notifiedUser`, data, httpOptions);
  }

}
