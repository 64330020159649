import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';

import { LicenseService } from '../services/license.service';

@Directive({
  selector: '[licenseRestriction]'
})
export class LicenseRestrictionDirective implements OnInit {
  @Input() licenseRestriction: string;

  constructor(private templateRef: TemplateRef<any>, private viewContainer: ViewContainerRef, private licenseService: LicenseService) { }

  ngOnInit() {
    this.licenseService.getSessionLicense().subscribe(() => {
      if (!this.licenseService.isPurchased(this.licenseRestriction)) {
        this.viewContainer.clear();
        return;
      } else {
        if (!this.viewContainer.length) this.viewContainer.createEmbeddedView(this.templateRef);
      }
    });
  }

}
