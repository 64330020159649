import { Component, OnDestroy, OnInit } from '@angular/core';

import { Subscription } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { LicenseService } from '../../services/license.service';
import { CommonService } from '../../services/common.service';
import { SupportModalComponent } from '../modals/support-modal/support-modal.component';

@Component({
  selector: 'app-marketplace',
  templateUrl: './marketplace.component.html',
  styleUrls: ['./marketplace.component.scss']
})
export class MarketplaceComponent implements OnInit, OnDestroy {
  productsSubscription: Subscription;
  products: any = {};

  constructor(private licenseService: LicenseService, private commonService: CommonService, private modalService: NgbModal) { }

  ngOnInit() {
    this.getProducts();
  }

  ngOnDestroy() {
    this.productsSubscription.unsubscribe();
  }

  getProducts() {
    this.productsSubscription = this.licenseService.getSessionLicense().subscribe((response: any) => {
      this.products = {
        connectors: this.commonService.groupBy(response.Connector, 'category'),
        modules: response.Module,
        templates: response['Conversational model']
      };
    });
  }

  modalSupport() {
    const modalRef = this.modalService.open(SupportModalComponent, {
      size: 'lg'
    });
    modalRef.componentInstance.support = { requestType: 'Quota Increase' };
  }

}
