
import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpRequest, HttpHandler, HttpErrorResponse } from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { NotificationService } from '../services/notification.service';
import { SupportModalComponent } from '../components/modals/support-modal/support-modal.component';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

    constructor(private notificationService: NotificationService, private modalService: NgbModal) {  }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            catchError((error: HttpErrorResponse) => {
                if ( error.status !== 403 && (error.status !== 401 && error.error !== 'SESSION') ) {
                    this.notificationService.openModal({
                        title: 'ERROR',
                        message: 'Ops... Something went wrong and it was impossible to complete the operation!',
                        type: 'danger',
                        choice: 'support',
                        serviceError: {
                            message: (error && error.error.message ? error.error.message  : 'Generic Error'),
                            code: error.status
                        },
                    }).subscribe(async (confirm: boolean) => {
                        if (!confirm) return;
                        
                        this.modalService.open(SupportModalComponent, {
                          size: 'lg'
                        });
                    })
                }
                return throwError(error);
            })
        );
    }
}
