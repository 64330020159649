import { Component, ElementRef, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { IntentService } from '../../../services/intent.service';
import { DesignService } from '../../../services/design.service';
import { CommonService } from '../../../services/common.service';
import { IntentFlow } from '../../../classes/intent-flow';
import { IntentModalComponent } from '../../modals/intent-modal/intent-modal.component';
import { DialogService } from '../../../services/dialog.service';
import { NotificationService } from '../../../services/notification.service';

@Component({
  selector: 'app-conversation-flow',
  templateUrl: './conversation-flow.component.html',
  styleUrls: ['./conversation-flow.component.scss']
})
export class ConversationFlowComponent implements OnInit, OnDestroy {
  @ViewChildren('intentName') intentName: QueryList<ElementRef>;
  
  subscriptions: Object = {};
  activeSidebar: boolean = false;
  chartFlow: IntentFlow;
  entities: Array<any> = [];

  intentForm: FormGroup = this.getFormGroups();
  intentSubmitted: boolean = false;

  get contextsIn() { return this.intentForm.get('inputContexts') as FormArray }
  get contextsOut() { return this.intentForm.get('outputContexts') as FormArray }
  get trainingPhrases() { return this.intentForm.get('phrases') as FormArray; }

  constructor(private fb: FormBuilder, private commonService: CommonService, private designService: DesignService, private intentService: IntentService, private dialogService: DialogService, private notificationService: NotificationService, private modalService: NgbModal) { }

  ngOnInit() {
    this.subscriptions['JourneySubscription'] = this.designService.getSessionJourney().subscribe(() => {
      this.getFlows();
    });

    this.subscriptions['ToggleMenu'] = this.commonService.onStretchMenu().subscribe(() => {
      setTimeout(() => {
        this.chartFlow.resetChart();
      }, 250);
    });
  }

  ngOnDestroy() {
    Object.keys(this.subscriptions).forEach((key: string) => {
      this.subscriptions[key].unsubscribe();
    });
  }

  ngAfterViewInit() {
    this.subscriptions['EllipsisName'] = this.intentName.changes.subscribe((elements: QueryList<ElementRef>) => {
      elements.forEach((element: ElementRef) => {
        if (element.nativeElement.offsetWidth < element.nativeElement.scrollWidth) {
          element.nativeElement.classList.add('ellipses');
        }
      })
    })
  }

  getFlows() {
    this.subscriptions['IntentFlows'] = this.intentService.getIntentFlows().subscribe((response: any) => {
      this.chartFlow = new IntentFlow(response.flow);
    });
  }

  getIntent(id: number) {
    this.subscriptions['IntentSubscription'] = this.intentService.getIntent(id).subscribe((response: any) => {
      this.intentForm.patchValue({
        id: response.id,
        dialogflowId: response.dialogflowId,
        name: response.name,
        action: response.action,
        events: response.events.toString()
      });
      this.fillArrayFields(response);
    });
  }

  getEntities(intentName: string) {
    this.subscriptions['Entities'] = this.dialogService.getEntities({intentName: intentName}).subscribe((response: any) => {
      this.entities = response.singleResult.parameters;
    });
  }

  preamblePrahase(intent: any): string {
    if (!intent.phrases[0]) return '';
    
    let preamble: string = '';
    intent.phrases[0].parts.forEach(part => preamble += part.text);
    if (preamble.length > 50) return `${preamble.slice(0, 50)}...`;
    else return preamble;
  }

  selectIntent(intent: any) {
    this.activeSidebar = true;

    this.intentForm = this.getFormGroups();
    this.getIntent(intent.id);
    this.getEntities(intent.name);
  }

  getFormGroups(): FormGroup {
    return this.fb.group({
      id: [''],
      dialogflowId: [''],
      name: [''],
      action: [''],
      events: [''],
      inputContexts: this.fb.array([]),
      outputContexts: this.fb.array([]),
      phrases: this.fb.array([]),
      webhookState: [false]
    });
  }

  fillArrayFields(intent: any) {
    const processParts = (parts: Array<any>): FormArray => {
      let partsArray = this.fb.array([]);
      parts.forEach(part => {
        partsArray.push(
          this.fb.group({
            alias: part.alias,
            entityType: part.entityType,
            text: part.text
          })
        )
      });
      return partsArray
    };

    intent.inputContexts.forEach((context: any) => {
      this.contextsIn.push(
        this.fb.group({ name: context.name })
      );
    });
    intent.outputContexts.forEach((context: any) => {
      this.contextsOut.push(
        this.fb.group({ name: context.name, lifespan: context.lifespan })
      );
    });
    intent.phrases.forEach(phrase => {
      this.trainingPhrases.push(
        this.fb.group({
          id: phrase.id,
          parts: processParts(phrase.parts),
          //type: phrase.type
        })
      );
    });
  }

  addTrainingPhrase($event) {
    $event.preventDefault();
    this.trainingPhrases.push(
      this.fb.group({
        parts: this.fb.array([ { alias: '', entityType: '', text: $event.target.value } ])
      })
    );
    $event.target.value = '';
  }

  saveIntent() {
    this.intentSubmitted = true;
    if (this.intentForm.invalid) return;

    const data = {...this.intentForm.value,
      events: this.intentForm.value.events ? [this.intentForm.value.events] : []
    }
    this.subscriptions['IntentSubscription'] = this.intentService.updateIntent(data).subscribe(() => {
      this.getFlows();
    });
  }

  deleteIntent() {
    this.subscriptions['ConfirmDelete'] = this.notificationService.openModal({
      title: 'Confirm delete Intent',
      message: `Are you sure to delete Intent <strong>${this.intentForm.value.name}</strong>`,
      choice: 'multi'
    }).subscribe((confirm: boolean) => {
      if (!confirm) return;

      this.subscriptions['IntentSubscription'] = this.intentService.deleteIntent(this.intentForm.value.id).subscribe(() => {
        this.activeSidebar = false;
        this.getFlows();
        this.notificationService.showToast('Intent correctly deleted', { type: 'success' });
      });
    })
  }

  intentModal() {
    const modalRef = this.modalService.open(IntentModalComponent, {
      size: 'lg'
    });
    modalRef.componentInstance.onSaveIntent.subscribe(() => this.getFlows());
  }

}
