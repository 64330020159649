import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormArray } from '@angular/forms';

import { Subject } from 'rxjs';

@Component({
  selector: 'steps-completion',
  templateUrl: './steps-completion.component.html',
  styleUrls: ['./steps-completion.component.scss'],
  host: { '[class.steps_container]': 'true' }
})
export class StepsCompletionComponent implements OnInit {
  @Input() phases: { current: number, steps: [{name: string, submitted: boolean}] };
  @Input() stepsForm: FormArray;
  @Input() onMoveStep: Subject<'prev' | 'next'>;
  @Output() onStepsCompleted = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
    this.onMoveStep.asObservable().subscribe((direction: 'prev' | 'next') => {
      if (direction === 'next' && this.phases.current === this.stepsForm.length - 1) {
        this.completeStep();
      } else {
        //if (direction === 'prev') this.changeStep(this.phases.current - 1);
        if (direction === 'next') this.changeStep(this.phases.current + 1);
      }
    });
  }

  changeStep(targetStep: number) {
    this.phases.steps[this.phases.current].submitted = true;
    if (targetStep !== 0 && this.stepsForm.controls.some((form, index) => form.invalid && index < targetStep)) return;
    
    this.phases.current = targetStep;
  }

  completeStep() {
    this.phases.steps[this.phases.current].submitted = true;
    if (this.stepsForm.at(this.phases.current).invalid) return;

    const data = this.stepsForm.value.reduce((a, b) => {return {...a, ...b}}, {});
    this.onStepsCompleted.emit(data);
  }

}
