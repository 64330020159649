import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpErrorResponse } from '@angular/common/http';

import { throwError, BehaviorSubject } from 'rxjs';
import { catchError, switchMap, take, filter } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { AuthenticationService } from '../services/authentication.service';
import { NotificationService } from '../services/notification.service';

const interceptExceptions: Array<string> = [
    `${environment.API.orchestrator_configurator}/auth/login`,
    `${environment.API.orchestrator_configurator}/auth/reset-password/start`,
    `${environment.API.orchestrator_configurator}/auth/reset-password/complete`,
    `${environment.API.orchestrator_configurator}/auth/token`
];

@Injectable()
export class AuthenticationInterceptor implements HttpInterceptor {
    private refreshingToken: boolean = false
    private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    constructor(private authenticationService: AuthenticationService, private notificationService: NotificationService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler) {
        if (interceptExceptions.indexOf(req.url) !== -1) return next.handle(req);

        return next.handle(req).pipe(
            catchError((error: HttpErrorResponse) => {
                if (error.status === 401) {
                    return this.handleTokenExpired(req, next);
                } else if (error.status === 403) {
                    this.authenticationService.eraseSession();
                }
                return throwError(error);
            })
        );
    }

    private handleTokenExpired(request: HttpRequest<any>, next: HttpHandler) {
        if (!this.refreshingToken) {
            this.refreshingToken = true;
            this.refreshTokenSubject.next(null);

            return this.authenticationService.refreshToken().pipe(
                switchMap(() => {
                    this.refreshingToken = false;
                    this.refreshTokenSubject.next('REFRESHED');
                    return next.handle(request);
                }),
                catchError((error: HttpErrorResponse) => {
                    this.refreshingToken = false;
                    this.authenticationService.eraseSession();
                    this.notificationService.showToast(`Session expired, please login again`, { type: 'warning' });
                    return throwError(error);
                })
            );
        } else {
            return this.refreshTokenSubject.pipe(
                filter(status => status != null),
                take(1),
                switchMap(() => {
                    return next.handle(request);
                })
            );
        }
    }

}
